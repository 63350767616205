import React, { useState, useEffect, useContext } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  IconButton,
  Stack
} from '@mui/material';
import { Print, MoreVert } from '@mui/icons-material';
import { getQuotationsByIdentifier } from '../../../../services/sales/quotationService';
import { formatCurrency } from '../../../../utils/helpers';
import { ProfileContext } from '../../../../context/profileContext';
import PrintQuotationModal from './printQuotationModal';

const ListQuotations = ({
  quotations,
  onSelectQuotation,
  selectedQuotationId,
  setQuotations,
  onNewQuotation,
  showRegisterButton = true,
}) => {
  const [localQuotations, setLocalQuotations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { selectedProfiles } = useContext(ProfileContext);
  const [printModalOpen, setPrintModalOpen] = useState(false);
  const [selectedQuotationForPrint, setSelectedQuotationForPrint] = useState(null);

  useEffect(() => {
    const fetchQuotations = async () => {
      setLoading(true);
      try {
        const fetchedQuotations = await Promise.all(
          selectedProfiles.map(profile =>
            getQuotationsByIdentifier(profile.cpf || profile.cnpj)
          )
        );
        const quotationsData = fetchedQuotations.flat();
        setLocalQuotations(quotationsData);
        if (setQuotations) setQuotations(quotationsData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    if (selectedProfiles.length > 0) {
      fetchQuotations();
    } else {
      setLocalQuotations([]);
      setLoading(false);
    }
  }, [selectedProfiles, setQuotations]);

  useEffect(() => {
    if (quotations) {
      setLocalQuotations(quotations);
    }
  }, [quotations]);

  const calculateTotal = (quotation) => {

    const servicosTotal = quotation.servicos?.reduce((total, servico) => 
      total + (servico.quantidade * servico.preco_unitario), 0) || 0;
    
    const produtosTotal = quotation.produtos?.reduce((total, produto) => 
      total + (produto.quantidade * produto.preco_unitario), 0) || 0;
        
    return servicosTotal + produtosTotal - (quotation.desconto || 0);
  };

  return (
    <div className="quotationCard">
      <Stack spacing={2}>
        {loading ? (
          <Typography variant="body2">Carregando orçamentos...</Typography>
        ) : error ? (
          <Typography variant="body2" color="error">{error}</Typography>
        ) : selectedProfiles.length === 0 ? (
          <Typography variant="body2">
            Selecione um perfil para visualizar os orçamentos associados.
          </Typography>
        ) : localQuotations.length === 0 ? (
          <Typography variant="body2">
            Nenhum orçamento encontrado para os perfis selecionados.
          </Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '80px' }}>OS</TableCell>
                  <TableCell style={{ width: '60px' }}>Status</TableCell>
                  <TableCell>Cliente/Referência</TableCell>
                  <TableCell>Valor Total</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {localQuotations.map((quotation) => (
                  <TableRow
                    key={quotation.id}
                    onClick={() => onSelectQuotation(quotation.id)}
                    hover
                    selected={quotation.id === selectedQuotationId}
                  >
                    <TableCell>{quotation.numero_os}</TableCell>
                    <TableCell>
                      {quotation.status === 'orcamento' && 'Orç'}
                      {quotation.status === 'aprovado' && 'Apr'}
                      {quotation.status === 'cancelado' && 'Canc'}
                      {quotation.status === 'concluido' && 'Conc'}
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {quotation.cliente}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {quotation.referencia}
                      </Typography>
                    </TableCell>
                    <TableCell>{formatCurrency(calculateTotal(quotation))}</TableCell>
                    <TableCell>
                      <IconButton onClick={(e) => {
                        e.stopPropagation();
                        setSelectedQuotationForPrint(quotation);
                        setPrintModalOpen(true);
                      }}>
                        <Print />
                      </IconButton>
                      <IconButton onClick={(e) => {
                        e.stopPropagation();
                        // Implementar menu de opções
                      }}>
                        <MoreVert />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        
        {showRegisterButton && (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={onNewQuotation}
            disabled={selectedProfiles.length === 0}
          >
            Novo Orçamento
          </Button>
        )}
      </Stack>
      <PrintQuotationModal
        open={printModalOpen}
        handleClose={() => setPrintModalOpen(false)}
        quotation={selectedQuotationForPrint}
      />
    </div>
  );
};

export default ListQuotations; 