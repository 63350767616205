import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem, Select, FormControl, InputLabel, Typography, Stack
} from '@mui/material';

import { tiposOperacao } from '../../../../config/constants';
import { OperationSelector } from '../../../../utils/formUtils';

import { createFinancialOperation } from '../../../../services/finance/financialOperationService';


const RegisterFinancialOperation = ({ open, onClose, parentOperation, allOperations, profileIdentifier }) => {
  const [operationData, setOperationData] = useState({
    nome: '',
    descricao: '',
    tipo_operacao: parentOperation ? parentOperation.tipo_operacao : '',
    operacao_pai_id: parentOperation ? parentOperation.id : null,
    ordem_exibicao: 0,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
 
  useEffect(() => {
    if (parentOperation) {
      setOperationData(prevData => ({
        ...prevData,
        operacao_pai_id: parentOperation.id,
        tipo_operacao: parentOperation.tipo_operacao,
      }));
    } else {
      setOperationData(prevData => ({
        ...prevData,
        operacao_pai_id: null,
        tipo_operacao: '',
      }));
    }
  }, [parentOperation]);

  useEffect(() => {
    if (allOperations) {
      const filteredOperations = allOperations.filter(op => 
        op.tipo_operacao === operationData.tipo_operacao &&
        (parentOperation ? op.id !== parentOperation.id : true)
      );
      
      if (parentOperation && !filteredOperations.some(op => op.id === parentOperation.id)) {
        filteredOperations.push(parentOperation);
      }
    }
  }, [allOperations, operationData.tipo_operacao, parentOperation]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOperationData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
    try {
      const newOperationData = {
        ...operationData,
        titular_cpf: profileIdentifier.replace(/\D/g, '').length === 11 ? profileIdentifier : null,
        titular_cnpj: profileIdentifier.replace(/\D/g, '').length === 14 ? profileIdentifier : null,
        nivel: parentOperation ? parentOperation.nivel + 1 : 1,
      };
        
      await createFinancialOperation(newOperationData);
      setSuccessMessage('Operação criada com sucesso.');
      setLoading(false);
      onClose();
    } catch (error) {
      setErrorMessage(error.message || 'Erro ao criar operação.');
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{parentOperation ? 'Adicionar Sub-operação' : 'Adicionar Operação Principal'}</DialogTitle>
      <DialogContent>
        {errorMessage && (
          <Typography variant="body2" color="error" gutterBottom>
            {errorMessage}
          </Typography>
        )}
        {successMessage && (
          <Typography variant="body2" color="primary" gutterBottom>
            {successMessage}
          </Typography>
        )}
        <Stack spacing={2}>
          <Stack>
            <TextField
              autoFocus
              margin="dense"
              name="nome"
              label="Nome"
              type="text"
              fullWidth
              value={operationData.nome}
              onChange={handleChange}
            />
          </Stack>
          <Stack>
            <TextField
              margin="dense"
              name="descricao"
              label="Descrição"
              type="text"
              fullWidth
              multiline
              rows={3}
              value={operationData.descricao}
              onChange={handleChange}
            />
          </Stack>
          <Stack>
            <FormControl fullWidth margin="dense">
              <InputLabel id="tipo_operacao_label">Tipo de Operação</InputLabel>
              <Select
                labelId="tipo_operacao_label"
                name="tipo_operacao"
                value={operationData.tipo_operacao}
                onChange={handleChange}
                label="Tipo de Operação"
              >
                {tiposOperacao.map(tipo => (
                  <MenuItem key={tipo.valor} value={tipo.valor}>{tipo.nome}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack>
            <OperationSelector
              value={operationData.operacao_pai_id || ''}
              onChange={(value) => handleChange({ target: { name: 'operacao_pai_id', value } })}
              titular={profileIdentifier}
              disabled={false}
              label="Operação Pai"
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancelar</Button>
        <Button onClick={handleSubmit} color="primary" disabled={loading}>Salvar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RegisterFinancialOperation;