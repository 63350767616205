// src/utils/fileUtils.js

import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { XMLParser } from 'fast-xml-parser';

export const readUploadedFile = (file) => {
  return new Promise((resolve, reject) => {
    const fileName = file.name.toLowerCase();

    if (fileName.endsWith('.csv')) {
      // Processamento de arquivos CSV
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          const columns = results.meta.fields;
          const rows = results.data;
          resolve({ columns, rows });
        },
        error: (error) => {
          reject(error);
        },
      });
    } else if (fileName.endsWith('.xlsx') || fileName.endsWith('.xls')) {
      // Processamento de arquivos Excel
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const columns = jsonData[0];
        const rows = jsonData.slice(1).map((row) => {
          const rowData = {};
          columns.forEach((col, index) => {
            rowData[col] = row[index];
          });
          return rowData;
        });
        resolve({ columns, rows });
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    } else if (fileName.endsWith('.xml')) {
      // Processamento de arquivos XML
      const reader = new FileReader();
      reader.onload = (e) => {
        const xmlText = e.target.result;
        const parser = new XMLParser();
        const jsonObj = parser.parse(xmlText);
        // Processar jsonObj conforme necessário
        // Exemplo simplificado:
        const transactions = jsonObj.transactions || [];
        const rows = transactions.map((transaction) => ({
          // Mapear os campos conforme necessário
        }));
        const columns = Object.keys(rows[0] || {});
        resolve({ columns, rows });
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsText(file);
    } else {
      // Arquivos OFX não são suportados no momento
      reject(new Error('Tipo de arquivo não suportado.'));
    }
  });
};
