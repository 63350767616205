// transactionService.js

import axios from 'axios';
import config from '../../config/apiConfig';
import {
  formatCpfForServer,
  formatCpfForDisplay,
  formatCnpjForServer,
  formatCnpjForDisplay,
  validateCpf,
  validateCnpj
} from '../../utils/helpers';

// Função auxiliar para tratamento de erros
const handleServiceError = (error, defaultMessage) => {
  console.error(defaultMessage, error);
  if (error.response && error.response.data && error.response.data.error) {
    return error.response.data.error;
  } else if (error.response && error.response.data && error.response.data.message) {
    return error.response.data.message;
  } else if (error.message) {
    return error.message;
  } else {
    return defaultMessage;
  }
};

export const createTransaction = async (transactionData) => {
  console.log('Iniciando createTransaction com dados:', transactionData);
  const token = localStorage.getItem('authToken');
  console.log('Token de autenticação obtido');

  try {
    // Limpa o campo titular para remover caracteres não numéricos
    const cleanIdentifier = transactionData.titular.replace(/\D/g, '');
    console.log('Identificador limpo:', cleanIdentifier);

    let formattedCpf = null;
    let formattedCnpj = null;

    // Determina se o identificador é CPF ou CNPJ com base no número de dígitos
    if (cleanIdentifier.length === 11) {
      console.log('Identificador reconhecido como CPF');
      if (!validateCpf(cleanIdentifier)) {
        console.error('CPF inválido:', cleanIdentifier);
        throw new Error('CPF inválido');
      }
      formattedCpf = formatCpfForServer(cleanIdentifier);
      console.log('CPF formatado para o servidor:', formattedCpf);
    } else if (cleanIdentifier.length === 14) {
      console.log('Identificador reconhecido como CNPJ');
      if (!validateCnpj(cleanIdentifier)) {
        console.error('CNPJ inválido:', cleanIdentifier);
        throw new Error('CNPJ inválido');
      }
      formattedCnpj = formatCnpjForServer(cleanIdentifier);
      console.log('CNPJ formatado para o servidor:', formattedCnpj);
    } else {
      console.error('Identificador inválido:', cleanIdentifier);
      throw new Error('Titular não foi selecionado. Por favor, selecione um titular.');
    }

    // Validação dos campos obrigatórios
    const requiredFields = ['tipo_transacao', 'valor', 'data_hora'];
    if (transactionData.tipo_transacao !== 'pagamento_cartao' && transactionData.tipo_transacao !== 'transferencia') {
      requiredFields.push('categoria');
    }
    for (const field of requiredFields) {
      if (!transactionData[field]) {
        throw new Error(`Campo obrigatório não preenchido: ${field}`);
      }
    }
    console.log('Todos os campos obrigatórios estão preenchidos');

    const jsonData = {
      ...transactionData,
      titular_cpf: formattedCpf,
      titular_cnpj: formattedCnpj,
    };
    console.log('Dados JSON preparados para envio:', jsonData);

    console.log('Enviando requisição para criar transação');
    const response = await axios.post(`${config.apiUrl+config.financeApiUrl}/transactions`, jsonData, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log('Resposta recebida do servidor:', response.data);

    // Formatação dos dados para exibição
    const transaction = response.data;
    if (transaction.titular_cpf) {
      transaction.titular_cpf = formatCpfForDisplay(transaction.titular_cpf);
      console.log('CPF formatado para exibição:', transaction.titular_cpf);
    }
    if (transaction.titular_cnpj) {
      transaction.titular_cnpj = formatCnpjForDisplay(transaction.titular_cnpj);
      console.log('CNPJ formatado para exibição:', transaction.titular_cnpj);
    }

    console.log('Transação criada com sucesso:', transaction);
    return transaction;
  } catch (error) {
    console.error('Erro capturado em createTransaction:', error);
    const errorMessage = handleServiceError(error, 'Erro ao criar transação.');
    throw new Error(errorMessage);
  }
};

export const updateTransaction = async (transactionID, updatedTransactionData) => {
  console.log('Iniciando updateTransaction:', { transactionID, updatedTransactionData });
  const token = localStorage.getItem('authToken');
  console.log('Token de autenticação obtido');

  try {
    let formattedCpf = null;
    let formattedCnpj = null;

    // Verifica se há um titular_cpf ou titular_cnpj nos dados atualizados
    if (updatedTransactionData.titular_cpf || updatedTransactionData.titular_cnpj) {
      const cleanIdentifier = (updatedTransactionData.titular_cpf || updatedTransactionData.titular_cnpj).replace(/\D/g, '');
      console.log('Identificador limpo:', cleanIdentifier);

      if (cleanIdentifier.length === 11) {
        console.log('Identificador reconhecido como CPF');
        if (!validateCpf(cleanIdentifier)) {
          console.error('CPF inválido:', cleanIdentifier);
          throw new Error('CPF inválido');
        }
        formattedCpf = formatCpfForServer(cleanIdentifier);
        console.log('CPF formatado para o servidor:', formattedCpf);
      } else if (cleanIdentifier.length === 14) {
        console.log('Identificador reconhecido como CNPJ');
        if (!validateCnpj(cleanIdentifier)) {
          console.error('CNPJ inválido:', cleanIdentifier);
          throw new Error('CNPJ inválido');
        }
        formattedCnpj = formatCnpjForServer(cleanIdentifier);
        console.log('CNPJ formatado para o servidor:', formattedCnpj);
      } else {
        console.error('Identificador inválido:', cleanIdentifier);
        throw new Error('Identificador inválido. Forneça um CPF ou CNPJ válido.');
      }
    }

    // Atualiza os dados com os valores formatados, se existirem
    const dataToUpdate = {
      ...updatedTransactionData,
      ...(formattedCpf && { titular_cpf: formattedCpf }),
      ...(formattedCnpj && { titular_cnpj: formattedCnpj }),
    };

    // Lógica atualizada para id_conta_bancaria e id_cartao
    if (updatedTransactionData.hasOwnProperty('id_conta_bancaria')) {
      dataToUpdate.id_conta_bancaria = updatedTransactionData.id_conta_bancaria !== "" ? updatedTransactionData.id_conta_bancaria : null;
      if (updatedTransactionData.id_conta_bancaria !== "") {
        dataToUpdate.id_cartao = null;
      }
    }
    if (updatedTransactionData.hasOwnProperty('id_cartao')) {
      dataToUpdate.id_cartao = updatedTransactionData.id_cartao !== "" ? updatedTransactionData.id_cartao : null;
      if (updatedTransactionData.id_cartao !== "") {
        dataToUpdate.id_conta_bancaria = null;
      }
    }

    console.log('Dados a serem enviados para atualização:', dataToUpdate);

    console.log('Enviando requisição para atualizar transação');
    const response = await axios.put(`${config.apiUrl+config.financeApiUrl}/transactions/${transactionID}`, dataToUpdate, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log('Resposta recebida do servidor:', response.data);

    // Formatação dos dados para exibição
    const transaction = response.data;
    if (transaction.titular_cpf) {
      transaction.titular_cpf = formatCpfForDisplay(transaction.titular_cpf);
      console.log('CPF formatado para exibição:', transaction.titular_cpf);
    }
    if (transaction.titular_cnpj) {
      transaction.titular_cnpj = formatCnpjForDisplay(transaction.titular_cnpj);
      console.log('CNPJ formatado para exibição:', transaction.titular_cnpj);
    }

    console.log('Transação atualizada com sucesso:', transaction);
    return transaction;
  } catch (error) {
    console.error('Erro capturado em updateTransaction:', error);
    throw handleServiceError(error, 'Erro ao atualizar transação.');
  }
};

export const getTransactionByID = async (transactionID) => {
  const token = localStorage.getItem('authToken');

  try {
    const response = await axios.get(`${config.apiUrl+config.financeApiUrl}/transactions/${transactionID}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    const transaction = response.data;

    // Formatação dos dados para exibição
    if (transaction.titular_cpf) {
      transaction.titular_cpf = formatCpfForDisplay(transaction.titular_cpf);
    }
    if (transaction.titular_cnpj) {
      transaction.titular_cnpj = formatCnpjForDisplay(transaction.titular_cnpj);
    }

    // Tratamento do campo titular_destino
    if (transaction.titular_destino) {
      const cleanDestino = transaction.titular_destino.replace(/\D/g, '');
      if (cleanDestino.length === 11) {
        transaction.titular_destino = formatCpfForDisplay(cleanDestino);
      } else if (cleanDestino.length === 14) {
        transaction.titular_destino = formatCnpjForDisplay(cleanDestino);
      }
    }

    return transaction;
  } catch (error) {
    throw handleServiceError(error, 'Erro ao buscar transação.');
  }
};

export const getTransactionsByDocumentNumber = async (documentNumber) => {
  const token = localStorage.getItem('authToken');

  try {
    const response = await axios.get(`${config.apiUrl+config.financeApiUrl}/transactions/document/${documentNumber}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    const transactions = response.data;

    // Formatação dos dados para exibição
    const formattedTransactions = transactions.map((transaction) => {
      if (transaction.titular_cpf) {
        transaction.titular_cpf = formatCpfForDisplay(transaction.titular_cpf);
      }
      if (transaction.titular_cnpj) {
        transaction.titular_cnpj = formatCnpjForDisplay(transaction.titular_cnpj);
      }
      return transaction;
    });

    return formattedTransactions;
  } catch (error) {
    throw handleServiceError(error, 'Erro ao buscar transações por número de documento.');
  }
};

export const getTransactions = async (filters) => {
  const token = localStorage.getItem('authToken');

  try {
    // Tratamento dos filtros
    const treatedFilters = Object.entries(filters).reduce((acc, [key, value]) => {
      if (value !== null && value !== undefined && value !== '') {
        if (key === 'titular_cpf') {
          acc[key] = formatCpfForServer(value.replace(/\D/g, ''));
        } else if (key === 'titular_cnpj') {
          acc[key] = formatCnpjForServer(value.replace(/\D/g, ''));
        } else if (key === 'data_inicio') {
          const startDate = new Date(value);
          startDate.setHours(0, 0, 0, 0);
          acc[key] = startDate.toISOString();
        } else if (key === 'data_fim') {
          const endDate = new Date(value);
          endDate.setHours(23, 59, 59, 999);
          acc[key] = endDate.toISOString();
        } else {
          acc[key] = value;
        }
      }
      return acc;
    }, {});

    const params = new URLSearchParams(treatedFilters).toString();

    const response = await axios.get(`${config.apiUrl+config.financeApiUrl}/transactions?${params}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    const transactions = response.data;

    // Formatação dos dados para exibição
    const formattedTransactions = transactions.map((transaction) => {
      if (transaction.titular_cpf) {
        transaction.titular_cpf = formatCpfForDisplay(transaction.titular_cpf);
      }
      if (transaction.titular_cnpj) {
        transaction.titular_cnpj = formatCnpjForDisplay(transaction.titular_cnpj);
      }
      return transaction;
    });

    return formattedTransactions;
  } catch (error) {
    throw handleServiceError(error, 'Erro ao buscar transações.');
  }
};
