import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import Sidebar from '../../../components/sidebar';
import Header from '../../../components/header';
import ListQuotations from './cards/listQuotations';
import QuotationInfo from './cards/quotationInfo';
import RegisterQuotation from './cards/registerQuotation';
import { getQuotationById } from '../../../services/sales/quotationService';
import './quotations.css';
import withAuth from '../../../hooks/withAuth';

const Quotations = () => {
  const [selectedQuotationId, setSelectedQuotationId] = useState(null);
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [quotations, setQuotations] = useState([]);

  const handleSelectQuotation = (quotationId) => {
    setSelectedQuotationId(quotationId);
    setShowRegisterForm(false);
  };

  const handleNewQuotation = () => {
    setSelectedQuotationId(null);
    setShowRegisterForm(true);
  };

  const handleQuotationRegistered = (newQuotation) => {
    setQuotations([newQuotation, ...quotations]);
    setSelectedQuotationId(newQuotation.id);
    setShowRegisterForm(false);
  };

  return (
    <div className="quotationContainer">
      <div className="sidebarContainer">
        <Sidebar />
      </div>
      <div className="contentContainer">
        <Header />
        <div className="quotationContent">
          <Box mt={4}>
            <Typography variant="h4" align="left" gutterBottom>
              Gestão de Orçamentos
            </Typography>
          </Box>
          <Grid container spacing={3}>
            {/* Lado Direito - Detalhes ou Formulário de Registro */}
            <Grid item xs={12} md={6}>
              {showRegisterForm ? (
                <RegisterQuotation
                  onQuotationRegistered={handleQuotationRegistered}
                />
              ) : selectedQuotationId ? (
                <QuotationInfo
                  quotationId={selectedQuotationId}
                  onUpdateQuotationList={(updatedQuotation) => {
                    setQuotations(quotations.map(q => 
                      q.id === updatedQuotation.id ? updatedQuotation : q
                    ));
                  }}
                />
              ) : (
                <Typography variant="body1">
                  Selecione um orçamento para visualizar os detalhes ou clique em "Novo Orçamento"
                </Typography>
              )}
            </Grid>
            {/* Lado Esquerdo - Lista de Orçamentos */}
            <Grid item xs={12} md={6}>
              <ListQuotations
                onSelectQuotation={handleSelectQuotation}
                onNewQuotation={handleNewQuotation}
                selectedQuotationId={selectedQuotationId}
                setQuotations={setQuotations}
                quotations={quotations}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default withAuth(Quotations); 