// multipleTransactionsForm.js

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Typography,
  Box,
  Button,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  Switch,
  Grid,
  IconButton,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputLabel, // Adicionado
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  TitularSelector,
  AccountSelector,
  CreditCardSelector,
  OperationSelector,
} from '../../../../utils/formUtils';
import { tiposTransacao, statusPagamento } from '../../../../config/constants';
import { readUploadedFile } from '../../../../utils/fileUtils';

import { createTransaction } from '../../../../services/finance/transactionService';

const MultipleTransactionsForm = ({
  initialAccountId, // Adicionado
  initialCreditCardId, // Adicionado
  initialTitular, // Adicionado
  onTransactionRegistered,
  setErrorMessage,
}) => {
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [fileColumns, setFileColumns] = useState([]);
  const [columnMapping, setColumnMapping] = useState({});
  const [fileData, setFileData] = useState([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const [columnHeaderMapping, setColumnHeaderMapping] = useState({});
  const [defaultValues, setDefaultValues] = useState({
    titular: initialTitular || '',
    id_conta_bancaria: initialAccountId || '',
    id_cartao: initialCreditCardId || '',
    operacao_financeira: '',
    status_pagamento: '',
    tipo_transacao: '',
  });
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [transactions, setTransactions] = useState([]);
  const [successfulTransactions, setSuccessfulTransactions] = useState([]);
  const [errorMessage, setInternalErrorMessage] = useState(''); // Adicionado

  const isTitularDisabled = Boolean(initialTitular);
  const isAccountDisabled = Boolean(initialAccountId);
  const isCreditCardDisabled = Boolean(initialCreditCardId);

  const systemColumns = [
    'titular',
    'nome_resumido',
    'tipo_transacao',
    'numero_documento',
    'valor',
    'data_hora',
    'parcelado',
    'numero_parcelas',
    'id_conta_bancaria',
    'id_cartao',
    'status_pagamento',
    'descricao',
    'operacao_financeira',
    'notas_adicionais',
  ];

  const addEmptyRow = useCallback(() => {
    setTransactions((prevTransactions) => [
      ...prevTransactions,
      {
        id: Date.now(),
        titular: defaultValues.titular || '',
        nome_resumido: '',
        tipo_transacao: defaultValues.tipo_transacao || '', // Adicionado
        numero_documento: '',
        valor: '',
        data_hora: '',
        parcelado: false,
        numero_parcelas: '',
        id_conta_bancaria: defaultValues.id_conta_bancaria || '',
        id_cartao: defaultValues.id_cartao || '',
        status_pagamento: defaultValues.status_pagamento || '', // Adicionado
        descricao: '',
        operacao_financeira: defaultValues.operacao_financeira || '',
        notas_adicionais: '',
      },
    ]);
  }, [setTransactions, defaultValues]);

  const handleDeleteRow = useCallback(
    (id) => {
      setTransactions((prevTransactions) => prevTransactions.filter((row) => row.id !== id));
    },
    [setTransactions]
  );

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    try {
      const { columns, rows } = await readUploadedFile(file);
      setFileColumns(columns);
      setFileData(rows);
    } catch (error) {
      console.error('Erro ao ler o arquivo:', error);
    }
  };

  const handleColumnMappingChange = (fileCol, systemCol) => {
    setColumnMapping({ ...columnMapping, [fileCol]: systemCol });
  };

  const applyColumnMapping = () => {
    const mappedData = fileData.map((row, index) => {
      const newRow = { id: Date.now() + index };
      Object.keys(columnMapping).forEach((fileCol) => {
        const systemCol = columnMapping[fileCol];
        let value = row[fileCol];

        // Tratamento especial para o campo data_hora
        if (systemCol === 'data_hora') {
          // Assumindo que a data está no formato DD/MM/YYYY
          const [day, month, year] = value.split('/');
          const parsedDate = new Date(year, month - 1, day);
          value = isNaN(parsedDate.getTime()) ? null : parsedDate;
        }

        newRow[systemCol] = value;
      });

      // Aplicar valores padrão se o campo estiver vazio
      Object.keys(defaultValues).forEach((key) => {
        if (!newRow[key]) {
          newRow[key] = defaultValues[key];
        }
      });

      return newRow;
    });
    setTransactions(mappedData);
  };

  const columns = useMemo(
    () => [
      {
        field: 'actions',
        headerName: 'Ações',
        width: 80,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <IconButton color="secondary" onClick={() => handleDeleteRow(params.id)}>
            <DeleteIcon />
          </IconButton>
        ),
      },
      {
        field: 'titular',
        headerName: 'Titular',
        width: 120,
        editable: true,
        renderEditCell: (params) => (
          <TitularSelector
            value={params.value}
            onChange={(value) => {
              params.api.setEditCellValue({ id: params.id, field: 'titular', value });
              // Resetar os campos relacionados ao titular
              params.api.setEditCellValue({ id: params.id, field: 'id_conta_bancaria', value: '' });
              params.api.setEditCellValue({ id: params.id, field: 'id_cartao', value: '' });
              params.api.setEditCellValue({ id: params.id, field: 'operacao_financeira', value: '' });
            }}
          />
        ),
      },
      {
        field: 'nome_resumido',
        headerName: 'Nome Resumido',
        width: 130,
        editable: true,
      },
      {
        field: 'tipo_transacao',
        headerName: 'Tipo de Transação',
        width: 150,
        editable: true,
        renderEditCell: (params) => (
          <Select
            value={params.value}
            onChange={(e) =>
              params.api.setEditCellValue({ id: params.id, field: 'tipo_transacao', value: e.target.value })
            }
            fullWidth
          >
            {tiposTransacao.map((tipo) => (
              <MenuItem key={tipo.valor} value={tipo.valor}>
                {tipo.nome}
              </MenuItem>
            ))}
          </Select>
        ),
      },
      {
        field: 'numero_documento',
        headerName: 'N. Documento',
        width: 120,
        editable: true,
      },
      {
        field: 'valor',
        headerName: 'Valor',
        width: 100,
        editable: true,
        type: 'number',
      },
      {
        field: 'data_hora',
        headerName: 'Data',
        width: 120,
        editable: true,
        type: 'date',
        renderCell: (params) => {
          if (!params.value) {
            return '';
          }
          let date;
          if (params.value instanceof Date) {
            date = params.value;
          } else {
            date = new Date(params.value);
          }
          if (isNaN(date.getTime())) {
            return 'Data inválida';
          }
          return date.toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          });
        },
        renderEditCell: (params) => (
          <input
            type="date"
            value={params.value instanceof Date ? params.value.toISOString().split('T')[0] : ''}
            onChange={(e) => {
              const newDate = new Date(e.target.value);
              params.api.setEditCellValue({ id: params.id, field: 'data_hora', value: newDate });
            }}
          />
        ),
      },
      {
        field: 'id_conta_bancaria',
        headerName: 'Conta Bancária',
        width: 150,
        editable: true,
        renderEditCell: (params) => (
          <AccountSelector
            value={params.value}
            onChange={(value) => params.api.setEditCellValue({ id: params.id, field: 'id_conta_bancaria', value })}
            titular={params.row.titular}
            disabled={!params.row.titular}
          />
        ),
      },
      {
        field: 'id_cartao',
        headerName: 'Cartão de Crédito',
        width: 150,
        editable: true,
        renderEditCell: (params) => (
          <CreditCardSelector
            value={params.value}
            onChange={(value) => params.api.setEditCellValue({ id: params.id, field: 'id_cartao', value })}
            titular={params.row.titular}
            disabled={!params.row.titular}
          />
        ),
      },
      {
        field: 'status_pagamento',
        headerName: 'Status de Pagamento',
        width: 150,
        editable: true,
        renderEditCell: (params) => (
          <Select
            value={params.value}
            onChange={(e) =>
              params.api.setEditCellValue({ id: params.id, field: 'status_pagamento', value: e.target.value })
            }
            fullWidth
          >
            {statusPagamento.map((status) => (
              <MenuItem key={status.valor} value={status.valor}>
                {status.nome}
              </MenuItem>
            ))}
          </Select>
        ),
      },
      {
        field: 'descricao',
        headerName: 'Descrição',
        width: 150,
        editable: true,
      },
      {
        field: 'operacao_financeira',
        headerName: 'Operação Financeira',
        width: 150,
        editable: true,
        renderEditCell: (params) => (
          <OperationSelector
            value={params.value}
            onChange={(value) => params.api.setEditCellValue({ id: params.id, field: 'operacao_financeira', value })}
            titular={params.row.titular}
            disabled={!params.row.titular}
          />
        ),
      },
      {
        field: 'notas_adicionais',
        headerName: 'Notas Adicionais',
        width: 150,
        editable: true,
      },
      {
        field: 'error',
        headerName: 'Erro',
        width: 200,
        renderCell: (params) => params.value || '',
      },
    ],
    [handleDeleteRow] // Removemos 'columnVisibilityModel' das dependências
  );

  useEffect(() => {
    const headerMapping = {};
    columns.forEach((col) => {
      headerMapping[col.field] = col.headerName;
    });
    setColumnHeaderMapping(headerMapping);
  }, [columns]);

  const handleDefaultValueChange = (field, value) => {
    setDefaultValues((prev) => {
      const newValues = { ...prev, [field]: value };
      
      // Lógica para limpar conta bancária ou cartão
      if (field === 'id_conta_bancaria' && value) {
        newValues.id_cartao = '';
        setColumnVisibilityModel((prev) => ({ ...prev, id_cartao: false }));
      } else if (field === 'id_cartao' && value) {
        newValues.id_conta_bancaria = '';
        setColumnVisibilityModel((prev) => ({ ...prev, id_conta_bancaria: false }));
      }

      return newValues;
    });
  };

  const defaultFields = [
    { field: 'titular', label: 'Titular', selector: TitularSelector, disabled: isTitularDisabled },
    { field: 'id_conta_bancaria', label: 'Conta Bancária', selector: AccountSelector, disabled: isAccountDisabled },
    { field: 'id_cartao', label: 'Cartão de Crédito', selector: CreditCardSelector, disabled: isCreditCardDisabled },
    { field: 'operacao_financeira', label: 'Operação Financeira', selector: OperationSelector },
    { field: 'status_pagamento', label: 'Status de Pagamento', selector: (props) => (
      <Select
        {...props}
        fullWidth
        onChange={(event) => props.onChange(event.target.value)} // Corrigido aqui
      >
        {statusPagamento.map((status) => (
          <MenuItem key={status.valor} value={status.valor}>
            {status.nome}
          </MenuItem>
        ))}
      </Select>
    )},
    { field: 'tipo_transacao', label: 'Tipo de Transação', selector: (props) => (
      <Select
        {...props}
        fullWidth
        onChange={(event) => props.onChange(event.target.value)} // Corrigido aqui
      >
        {tiposTransacao.map((tipo) => (
          <MenuItem key={tipo.valor} value={tipo.valor}>
            {tipo.nome}
          </MenuItem>
        ))}
      </Select>
    )},
  ];

  const handleRegisterTransactions = async () => {
    console.log('Iniciando registro de múltiplas transações');
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
  
    const updatedTransactions = [...transactions];
    const newSuccessfulTransactions = [];
  
    for (let i = 0; i < updatedTransactions.length; i++) {
      const transaction = updatedTransactions[i];
      try {
        console.log('Registrando transação:', transaction);
        const createdTransaction = await createTransaction(transaction);
        console.log('Transação registrada com sucesso:', createdTransaction);
        newSuccessfulTransactions.push(createdTransaction);
        updatedTransactions.splice(i, 1);
        i--;
      } catch (error) {
        console.error('Erro ao criar transação:', error);
        transaction.error = error.message;
      }
    }
  
    setTransactions(updatedTransactions);
    setSuccessfulTransactions([...successfulTransactions, ...newSuccessfulTransactions]);
    setLoading(false);
    
    if (newSuccessfulTransactions.length > 0) {
      setSuccessMessage(`${newSuccessfulTransactions.length} transação(ões) registrada(s) com sucesso!`);
    }
    if (updatedTransactions.length > 0) {
      setErrorMessage(`${updatedTransactions.length} transação(ões) não pôde(puderam) ser registrada(s). Verifique os erros na tabela.`);
    }
  };

  const formatCurrency = (value) => {
    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  // Log das informações recebidas
  useEffect(() => {
    console.log('Informações recebidas no MultipleTransactionsForm:', {
      initialAccountId,
      initialCreditCardId,
      initialTitular,
    });
  }, [initialAccountId, initialCreditCardId, initialTitular]);

  // Atualize o estado inicial com os valores recebidos
  useEffect(() => {
    setDefaultValues((prev) => ({
      ...prev,
      titular: initialTitular || '',
      id_conta_bancaria: initialAccountId || '',
      id_cartao: initialCreditCardId || '',
    }));
  }, [initialTitular, initialAccountId, initialCreditCardId]);

  return (
    <div className="accountInfoCard fullWidthCard">
      <Box>
        <Typography variant="h6" gutterBottom>
          Cadastrar Múltiplas Transações
        </Typography>
        {errorMessage && (
          <Typography variant="body2" color="error" gutterBottom>
            {errorMessage}
          </Typography>
        )}
        {!isInitialized && (
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setIsInitialized(true);
                setTransactions([]);
              }}
            >
              Iniciar Nova Tabela
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setShowFileUpload(true);
                setIsInitialized(true);
              }}
            >
              Importar Arquivo
            </Button>
          </Box>
        )}

        {isInitialized && (
          <Box mt={2}>
            <Typography variant="h6">Configurações Iniciais</Typography>
            <Grid container spacing={2}>
              {defaultFields.map(({ field, label, selector: SelectorComponent, disabled }) => (
                <Grid item xs={12} sm={6} md={4} key={field}>
                  <Typography variant="subtitle1">{label}</Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={columnVisibilityModel[field] !== false}
                        onChange={(e) => {
                          setColumnVisibilityModel((prev) => ({
                            ...prev,
                            [field]: e.target.checked,
                          }));
                        }}
                      />
                    }
                    label={`Exibir coluna ${label}`}
                  />
                  <SelectorComponent
                    value={defaultValues[field]}
                    onChange={(value) => handleDefaultValueChange(field, value)}
                    titular={field !== 'titular' ? defaultValues.titular : undefined}
                    disabled={disabled}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        {showFileUpload && (
          <Box mt={2}>
            <input
              accept=".csv, .xml, .xls, .xlsx"
              style={{ display: 'none' }}
              id="file-upload"
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="file-upload">
              <Button variant="contained" color="primary" component="span">
                Selecionar Arquivo
              </Button>
            </label>

            {fileColumns.length > 0 && (
              <Box mt={2}>
                <Typography variant="h6">Mapeamento de Colunas</Typography>
                {fileColumns.map((fileCol) => (
                  <FormControl fullWidth margin="normal" key={fileCol}>
                    <InputLabel>{`Coluna do Arquivo: ${fileCol}`}</InputLabel>
                    <Select
                      value={columnMapping[fileCol] || ''}
                      onChange={(e) => handleColumnMappingChange(fileCol, e.target.value)}
                    >
                      {systemColumns.map((sysCol) => (
                        <MenuItem value={sysCol} key={sysCol}>
                          {columnHeaderMapping[sysCol] || sysCol}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ))}
                <Button variant="contained" color="primary" onClick={applyColumnMapping}>
                  Aplicar Mapeamento
                </Button>
              </Box>
            )}
          </Box>
        )}

        {isInitialized && (
          <Box mt={2}>
            <Button variant="contained" color="primary" onClick={addEmptyRow}>
              Adicionar Nova Linha
            </Button>
          </Box>
        )}

        {successfulTransactions.length > 0 && (
          <Box mt={4}>
            <Typography variant="h6" gutterBottom>
              Transações Registradas com Sucesso
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Data</TableCell>
                    <TableCell>Tipo</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Operação Financeira</TableCell>
                    <TableCell>Valor</TableCell>
                    <TableCell>Descrição</TableCell>
                    <TableCell>Titular</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {successfulTransactions.map((transaction) => (
                    <TableRow key={transaction.id}>
                      <TableCell>{new Date(transaction.data_hora).toLocaleDateString('pt-BR')}</TableCell>
                      <TableCell>{transaction.tipo_transacao === 'receita' ? 'R' : 'D'}</TableCell>
                      <TableCell>{transaction.status_pagamento}</TableCell>
                      <TableCell>{transaction.operacao_financeira}</TableCell>
                      <TableCell>{formatCurrency(transaction.valor)}</TableCell>
                      <TableCell>{transaction.nome_resumido}</TableCell>
                      <TableCell>{transaction.titular_cpf || transaction.titular_cnpj}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}

        {transactions.length > 0 && (
          <Box mt={4}>
            <Typography variant="h6" gutterBottom>
              Transações Pendentes
            </Typography>
            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={transactions}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
                checkboxSelection={false}
                disableSelectionOnClick
                getRowId={(row) => row.id}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
              />
            </div>
          </Box>
        )}

        <Box mt={2}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleRegisterTransactions}
            disabled={loading || transactions.length === 0}
          >
            {loading ? 'Registrando...' : 'Registrar Transações'}
          </Button>
        </Box>

        {/* Exibição de mensagens */}
        <Snackbar
          open={Boolean(successMessage)}
          autoHideDuration={6000}
          onClose={() => setSuccessMessage('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
            {successMessage}
          </Alert>
        </Snackbar>

        <Snackbar
          open={Boolean(errorMessage)}
          autoHideDuration={6000}
          onClose={() => setInternalErrorMessage('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={() => setInternalErrorMessage('')} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>
      </Box>
    </div>
  );
};

export default MultipleTransactionsForm;