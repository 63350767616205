import React, { useState, useEffect, useCallback } from 'react';
import { Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import Visibility from '@mui/icons-material/Visibility';

import { getInitials } from '../../../../utils/helpers';
import apiConfig from '../../../../config/apiConfig';

import AddTeamMemberModal from './addTeamMember';
import ViewTeamMemberModal from './viewTeamMember';

import { getCompanyData, getTeamMembers, removeTeamMember } from '../../../../services/profile/companyProfileService';
import { getUserData, getProfileDataByCpf } from '../../../../services/profile/userProfileService';



const TeamCard = ({ cnpj }) => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedMemberToDelete, setSelectedMemberToDelete] = useState(null);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [selectedMemberToView, setSelectedMemberToView] = useState(null);
  const [cpfResponsavel, setCpfResponsavel] = useState(null);
  const [userCpf, setUserCpf] = useState(localStorage.getItem('userCpf'));
  const [razaoSocial, setRazaoSocial] = useState('');
  const [errorMessage, setErrorMessage] = useState('');  // Novo estado para a mensagem de erro
  const [successMessage, setSuccessMessage] = useState('');  // Novo estado para a mensagem de sucesso

  const fetchData = useCallback(async () => {
    if (!cnpj) return;

    try {
      const members = await getTeamMembers(cnpj);
      const fullMembersData = await Promise.all(
        members.map(async (member) => {
          const profile = await getProfileDataByCpf(member.cpf_usuario); 
          return {
            ...member,
            nome: profile.nome,
            sobrenome: profile.sobrenome,
            nome_completo: `${profile.nome} ${profile.sobrenome}`,
            profile_image: profile.profile_image,
            telefone: profile.telefone,
            cpf_formatado: profile.cpf
          };
        })
      );
      setTeamMembers(fullMembersData);

      const companyData = await getCompanyData(cnpj);
      setCpfResponsavel(companyData.cpf_responsavel);
      setRazaoSocial(companyData.razao_social);

      if (!userCpf) {
        const userData = await getUserData();
        setUserCpf(userData.cpf_usuario);
        localStorage.setItem('userCpf', userData.cpf_usuario);
      }
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
      setErrorMessage('Erro ao carregar os dados da equipe.');
    }
  }, [cnpj, userCpf]);

  useEffect(() => {
    fetchData();
  }, [cnpj, fetchData]);

  const handleOpenDeleteDialog = (member) => {
    setSelectedMemberToDelete(member);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedMemberToDelete(null);
  };

  const handleRemoveMember = async () => {
    if (!selectedMemberToDelete) return;

    try {
      await removeTeamMember(cnpj, selectedMemberToDelete.cpf_usuario);
      setTeamMembers(teamMembers.filter(member => member.cpf_usuario !== selectedMemberToDelete.cpf_usuario));
      setSuccessMessage('Membro removido com sucesso!');
      setErrorMessage('');  // Limpa a mensagem de erro se a remoção for bem-sucedida
      handleCloseDeleteDialog();
    } catch (error) {
      console.error('Error removing team member:', error);
      setErrorMessage('Erro ao remover membro da equipe.');
      setSuccessMessage('');  // Limpa a mensagem de sucesso se ocorrer um erro
    }
  };

  const handleOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const handleAddSuccess = async (cpf) => {
    await fetchData();
    setSuccessMessage('Membro adicionado com sucesso!');
    setErrorMessage('');  // Limpa a mensagem de erro após o sucesso
  };

  const handleOpenViewDialog = (member) => {
    setSelectedMemberToView(member);
    setOpenViewDialog(true);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    setSelectedMemberToView(null);
  };

  return (
    <div className="teamCard">
      <Typography variant="h5">Membros da Equipe</Typography>
      <Typography variant="subtitle1">{razaoSocial} - {cnpj}</Typography>

      {/* Espaço para exibir a mensagem de erro ou sucesso */}
      {errorMessage && (
        <Typography variant="body2" color="error" gutterBottom>
          {errorMessage}
        </Typography>
      )}
      {successMessage && (
        <Typography variant="body2" color="primary" gutterBottom>
          {successMessage}
        </Typography>
      )}

      <Box my={2}></Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Nome Completo</TableCell>
              <TableCell>CPF</TableCell>
              <TableCell>Telefone</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teamMembers.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">Nenhum membro da equipe encontrado.</TableCell>
              </TableRow>
            ) : (
              teamMembers.map((member, index) => (
                <TableRow key={member.id || index} hover>
                  <TableCell>
                    {member.profile_image ? (
                      <img src={`${apiConfig.serverUrl}${member.profile_image}`} alt="Pessoa" className="profileAvatarSmall" />
                    ) : (
                      <div className="profileAvatarSmall">{getInitials(member.nome, member.sobrenome)}</div>
                    )}
                  </TableCell>
                  <TableCell>{member.nome_completo}</TableCell>
                  <TableCell>{member.cpf_formatado}</TableCell>
                  <TableCell>{member.telefone}</TableCell>
                  <TableCell>
                    <IconButton color="primary" onClick={() => handleOpenViewDialog(member)} title="Vizualizar Dados Pessoais">
                      <Visibility />
                    </IconButton>
                    {userCpf === cpfResponsavel && member.cpf_usuario !== cpfResponsavel && (
                      <IconButton color="secondary" onClick={() => handleOpenDeleteDialog(member)} title="Excluir Membro da Equipe">
                        <Delete />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box my={2}></Box>
      {userCpf === cpfResponsavel && (
        <Button variant="outlined" fullWidth color="primary" onClick={handleOpenAddDialog}>
          Adicionar Membro da Equipe
        </Button>
      )}

      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirmar Remoção</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deseja remover {selectedMemberToDelete && `${selectedMemberToDelete.nome_completo}`} da equipe?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">Cancelar</Button>
          <Button onClick={handleRemoveMember} color="secondary">Remover</Button>
        </DialogActions>
      </Dialog>

      <AddTeamMemberModal
        open={openAddDialog}
        handleClose={handleCloseAddDialog}
        onAddSuccess={handleAddSuccess}
        cnpj={cnpj}
      />

      <ViewTeamMemberModal
        open={openViewDialog}
        handleClose={handleCloseViewDialog}
        member={selectedMemberToView}
      />
    </div>
  );
};

export default TeamCard;
