import React, { useState } from 'react';
import {
  Typography,
  TextField,
  Button,
  Box,
  Grid,
  MenuItem
} from '@mui/material';
import { createQuotation } from '../../../../services/sales/quotationService';
import { TitularSelector } from '../../../../utils/formUtils';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { formatCurrency } from '../../../../utils/helpers';
import { statusOrcamento } from '../../../../config/constants';

const RegisterQuotation = ({ onQuotationRegistered }) => {
  const [quotationData, setQuotationData] = useState({
    titular: null,
    cliente: '',
    referencia: '',
    observacoes: '',
    servicos: [{ descricao: '', quantidade: 0, preco_unitario: 0 }],
    produtos: [{ descricao: '', quantidade: 0, preco_unitario: 0 }],
    desconto: 0,
    status: 'orcamento'
  });
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuotationData({
      ...quotationData,
      [name]: value
    });
  };

  const handleServiceChange = (index, field, value) => {
    const newServicos = [...quotationData.servicos];
    newServicos[index][field] = value;
    setQuotationData({
      ...quotationData,
      servicos: newServicos
    });
  };

  const handleProductChange = (index, field, value) => {
    const newProdutos = [...quotationData.produtos];
    newProdutos[index][field] = value;
    setQuotationData({
      ...quotationData,
      produtos: newProdutos
    });
  };

  const addNewService = () => {
    setQuotationData({
      ...quotationData,
      servicos: [...quotationData.servicos, { descricao: '', quantidade: 0, preco_unitario: 0 }]
    });
  };

  const addNewProduct = () => {
    setQuotationData({
      ...quotationData,
      produtos: [...quotationData.produtos, { descricao: '', quantidade: 0, preco_unitario: 0 }]
    });
  };

  const removeService = (index) => {
    const newServicos = quotationData.servicos.filter((_, i) => i !== index);
    setQuotationData({
      ...quotationData,
      servicos: newServicos
    });
  };

  const removeProduct = (index) => {
    const newProdutos = quotationData.produtos.filter((_, i) => i !== index);
    setQuotationData({
      ...quotationData,
      produtos: newProdutos
    });
  };

  const handleRegister = async () => {
    try {
      const newQuotation = await createQuotation(quotationData);
      onQuotationRegistered(newQuotation);
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(`Erro ao registrar novo orçamento: ${error.message}`);
    }
  };

  return (
    <div className="quotationInfoCard">
      <Typography variant="h5" className="quotationTitle">
        Cadastrar Novo Orçamento
      </Typography>

      {errorMessage && (
        <Typography variant="body2" color="error" gutterBottom>
          {errorMessage}
        </Typography>
      )}
      <Box my={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TitularSelector
              value={quotationData.titular}
              onChange={(titular) => setQuotationData({...quotationData, titular})}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Cliente"
              name="cliente"
              value={quotationData.cliente}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Referência"
              name="referencia"
              value={quotationData.referencia}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className="sectionTitle">
              Serviços
            </Typography>
            {quotationData.servicos.map((servico, index) => (
              <Box key={index} sx={{ display: 'flex', gap: 2, mb: 2 }}>
                <TextField
                  label="Descrição"
                  value={servico.descricao}
                  onChange={(e) => handleServiceChange(index, 'descricao', e.target.value)}
                  sx={{ flex: 2 }}
                />
                <TextField
                  label="Horas/Qtde"
                  type="number"
                  value={servico.quantidade}
                  onChange={(e) => handleServiceChange(index, 'quantidade', Number(e.target.value))}
                  sx={{ flex: 1 }}
                />
                <TextField
                  label="Valor Unitário"
                  type="number"
                  value={servico.preco_unitario}
                  onChange={(e) => handleServiceChange(index, 'preco_unitario', Number(e.target.value))}
                  sx={{ flex: 1 }}
                />
                <TextField
                  label="Valor Total"
                  value={formatCurrency(servico.quantidade * servico.preco_unitario)}
                  disabled
                  sx={{ flex: 1 }}
                />
                <IconButton onClick={() => removeService(index)} color="error">
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
            <Button startIcon={<AddIcon />} onClick={addNewService}>
              Adicionar Serviço
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" className="sectionTitle">
              Produtos
            </Typography>
            {quotationData.produtos.map((produto, index) => (
              <Box key={index} sx={{ display: 'flex', gap: 2, mb: 2 }}>
                <TextField
                  label="Descrição"
                  value={produto.descricao}
                  onChange={(e) => handleProductChange(index, 'descricao', e.target.value)}
                  sx={{ flex: 2 }}
                />
                <TextField
                  label="Quantidade"
                  type="number"
                  value={produto.quantidade}
                  onChange={(e) => handleProductChange(index, 'quantidade', Number(e.target.value))}
                  sx={{ flex: 1 }}
                />
                <TextField
                  label="Valor Unitário"
                  type="number"
                  value={produto.preco_unitario}
                  onChange={(e) => handleProductChange(index, 'preco_unitario', Number(e.target.value))}
                  sx={{ flex: 1 }}
                />
                <TextField
                  label="Valor Total"
                  value={formatCurrency(produto.quantidade * produto.preco_unitario)}
                  disabled
                  sx={{ flex: 1 }}
                />
                <IconButton onClick={() => removeProduct(index)} color="error">
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
            <Button startIcon={<AddIcon />} onClick={addNewProduct}>
              Adicionar Produto
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Total de Serviços"
                value={formatCurrency(
                  quotationData.servicos.reduce((total, servico) => 
                    total + (servico.quantidade * servico.preco_unitario), 0
                  )
                )}
                disabled
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Total de Produtos"
                value={formatCurrency(
                  quotationData.produtos.reduce((total, produto) => 
                    total + (produto.quantidade * produto.preco_unitario), 0
                  )
                )}
                disabled
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Desconto"
                name="desconto"
                type="number"
                value={quotationData.desconto}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Valor Total do Orçamento"
                value={formatCurrency(
                  quotationData.servicos.reduce((total, servico) => 
                    total + (servico.quantidade * servico.preco_unitario), 0
                  ) +
                  quotationData.produtos.reduce((total, produto) => 
                    total + (produto.quantidade * produto.preco_unitario), 0
                  ) -
                  quotationData.desconto
                )}
                disabled
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                fullWidth
                label="Status"
                name="status"
                value={quotationData.status}
                onChange={handleChange}
                variant="outlined"
              >
                {statusOrcamento.map((option) => (
                  <MenuItem key={option.valor} value={option.valor}>
                    {option.nome}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Observações"
              name="observacoes"
              value={quotationData.observacoes}
              onChange={handleChange}
              variant="outlined"
              multiline
              rows={4}
            />
          </Grid>


          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleRegister}
            >
              Cadastrar Orçamento
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default RegisterQuotation; 