import React, { useState, useEffect } from 'react';
import { Typography, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';
import Share from '@mui/icons-material/Share';
import ShareProfileModal from './shareProfile';
import { getInitials } from '../../../../utils/helpers';
import config from '../../../../config/apiConfig';
import { getUserData, getProfileDataByCpf } from '../../../../services/profile/userProfileService';


const PersonalProfiles = ({ refreshProfiles, onSelectProfile, selectedProfile, setPersonalProfiles, personalProfiles, refreshProfileAccess, token }) => {
  const userCpf = localStorage.getItem('userCpf') || ''; // Obtém o CPF formatado do localStorage
  const [openShareDialog, setOpenShareDialog] = useState(false);

useEffect(() => {
  const fetchPersonalProfiles = async () => {
    try {
      // Obter o perfil do usuário logado
      const userProfile = await getProfileDataByCpf(userCpf, token);

      // Montar o perfil com o nome completo e CPF formatado para exibição
      const userProfileWithFormattedCpf = {
        ...userProfile,
        nome_completo: `${userProfile.nome} ${userProfile.sobrenome}`,
        cpf: userProfile.cpf, // CPF formatado para exibição
      };

      const profiles = [userProfileWithFormattedCpf];

      // Obter perfis adicionais aos quais o usuário tem acesso
      const data = await getUserData();
      if (data) {
        const perfisCompartilhados = data.perfil_compartilhado || []; // Garante que perfil_compartilhado é um array

        const additionalProfiles = await Promise.all(
          perfisCompartilhados.map(async (cpf) => {
            const profile = await getProfileDataByCpf(cpf, token);
            return {
              ...profile,
              nome_completo: `${profile.nome} ${profile.sobrenome}`,
              cpf: profile.cpf, // CPF formatado para exibição
            };
          })
        );

        setPersonalProfiles([...profiles, ...additionalProfiles]);
      }
    } catch (error) {
      console.error('Erro ao buscar perfis pessoais:', error);
    }
  };

  fetchPersonalProfiles();
}, [refreshProfiles, setPersonalProfiles, token, userCpf]);


  const handleSelectProfile = (cpf) => {
    onSelectProfile(cpf);
  };

  const handleOpenShareDialog = () => {
    setOpenShareDialog(true);
  };

  const handleCloseShareDialog = () => {
    setOpenShareDialog(false);
  };

  const handleShareSuccess = () => {
    setOpenShareDialog(false);
    refreshProfileAccess();  // Força a atualização do ProfileAccess
  };

  return (
    <div className="profileCard">
      <Typography variant="h5">Seus Perfis Pessoais</Typography>
      <Box my={2}></Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Nome Completo</TableCell>
              <TableCell>CPF</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {personalProfiles.map((profile, index) => (
              <TableRow 
                key={`${profile.cpf}-${index}`} // Combinação de cpf e index para garantir unicidade
                selected={selectedProfile === profile.cpf}
                onClick={() => handleSelectProfile(profile.cpf)}
                hover
              >
                <TableCell>
                  {profile.profile_image ? (
                    <img src={`${config.serverUrl}${profile.profile_image}`} alt="Pessoa" className="profileAvatarSmall" />
                  ) : (
                    <div className="profileAvatarSmall">
                      {getInitials(profile.nome, profile.sobrenome)}
                    </div>
                  )}
                </TableCell>
                <TableCell>{profile.nome_completo}</TableCell>
                <TableCell>{profile.cpf}</TableCell>
                <TableCell>
                  {profile.cpf === userCpf && (
                    <Box display="flex" gap={1}>
                      <IconButton color="primary" onClick={handleOpenShareDialog} title="Compartilhar">
                        <Share />
                      </IconButton>
                    </Box>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <ShareProfileModal 
        open={openShareDialog} 
        handleClose={handleCloseShareDialog} 
        onShareSuccess={handleShareSuccess} 
      />
    </div>
  );
};

export default PersonalProfiles;
