import React from 'react';
import './notifications.css';
import { FaBell } from 'react-icons/fa'; // Importando o ícone de notificação

const Notifications = ({ isOpen, setIsOpen }) => {
  const notifications = [
    "Notificação 1",
    "Notificação 2",
    "Notificação 3",
  ]; // Substituir por notificações reais obtidas dinamicamente

  return (
    <div className="notifications">
      <button
        className="notifications__button"
        onClick={() => setIsOpen(!isOpen)}
      >
        <FaBell /> {/* Ícone de notificação */}
      </button>
      {isOpen && (
        <ul className="notifications__dropdown">
          {notifications.map((notification, index) => (
            <li key={index} className="notifications__item">
              {notification}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Notifications;
