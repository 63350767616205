import React, { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Button,
  Box,
  Grid,
  MenuItem,
  IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { TitularSelector } from '../../../../utils/formUtils';
import { statusOrcamento } from '../../../../config/constants';
import { getQuotationById, updateQuotation } from '../../../../services/sales/quotationService';
import { getCompanyData } from '../../../../services/profile/companyProfileService';
import { getProfileDataByCpf } from '../../../../services/profile/userProfileService';
import { formatCurrency } from '../../../../utils/helpers';

const QuotationInfo = ({ quotationId, onUpdateQuotationList }) => {
  const [quotationData, setQuotationData] = useState({});
  const [originalData, setOriginalData] = useState({});
  const [titularName, setTitularName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchQuotationData = async () => {
      try {
        setErrorMessage('');
        setSuccessMessage('');
        const quotation = await getQuotationById(quotationId);
        setQuotationData(quotation);
        setOriginalData(quotation);

        if (quotation.titular_cpf) {
          await fetchTitularName(quotation.titular_cpf);
        } else if (quotation.titular_cnpj) {
          await fetchTitularCompanyName(quotation.titular_cnpj);
        }
        setLoading(false);
      } catch (err) {
        setError('Erro ao buscar dados do orçamento');
        setLoading(false);
      }
    };

    fetchQuotationData();
  }, [quotationId]);

  const fetchTitularName = async (cpf) => {
    try {
      const profileData = await getProfileDataByCpf(cpf);
      setTitularName(`${profileData.nome} ${profileData.sobrenome}`);
    } catch (err) {
      setError('Erro ao buscar dados do titular');
    }
  };

  const fetchTitularCompanyName = async (cnpj) => {
    try {
      const companyData = await getCompanyData(cnpj);
      setTitularName(companyData.razao_social);
    } catch (err) {
      setError('Erro ao buscar dados da empresa titular');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuotationData({
      ...quotationData,
      [name]: value
    });
    setIsChanged(true);
  };

  const handleServiceChange = (index, field, value) => {
    const newServicos = [...quotationData.servicos];
    newServicos[index][field] = value;
    setQuotationData({
      ...quotationData,
      servicos: newServicos
    });
    setIsChanged(true);
  };

  const handleProductChange = (index, field, value) => {
    const newProdutos = [...quotationData.produtos];
    newProdutos[index][field] = value;
    setQuotationData({
      ...quotationData,
      produtos: newProdutos
    });
    setIsChanged(true);
  };

  const addNewService = () => {
    setQuotationData({
      ...quotationData,
      servicos: [...quotationData.servicos, { descricao: '', quantidade: 0, preco_unitario: 0 }]
    });
    setIsChanged(true);
  };

  const addNewProduct = () => {
    setQuotationData({
      ...quotationData,
      produtos: [...quotationData.produtos, { descricao: '', quantidade: 0, preco_unitario: 0 }]
    });
    setIsChanged(true);
  };

  const removeService = (index) => {
    const newServicos = quotationData.servicos.filter((_, i) => i !== index);
    setQuotationData({
      ...quotationData,
      servicos: newServicos
    });
    setIsChanged(true);
  };

  const removeProduct = (index) => {
    const newProdutos = quotationData.produtos.filter((_, i) => i !== index);
    setQuotationData({
      ...quotationData,
      produtos: newProdutos
    });
    setIsChanged(true);
  };

  const handleSaveChanges = async () => {
    try {
      const updatedQuotation = await updateQuotation(quotationId, quotationData);
      setOriginalData(quotationData);
      setIsChanged(false);
      setSuccessMessage('Orçamento atualizado com sucesso!');
      setErrorMessage('');

      if (onUpdateQuotationList) {
        onUpdateQuotationList(updatedQuotation);
      }
    } catch (err) {
      setErrorMessage(`Erro ao atualizar orçamento: ${err.message}`);
      setSuccessMessage('');
    }
  };

  if (loading) return <Typography>Carregando...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!quotationData) return <Typography color="error">Orçamento não encontrado.</Typography>;

  return (
    <div className="quotationInfoCard">
      <Typography variant="h5" className="quotationTitle">
        Detalhes do Orçamento
      </Typography>

      {errorMessage && (
        <Typography variant="body2" color="error" gutterBottom>
          {errorMessage}
        </Typography>
      )}
      {successMessage && (
        <Typography variant="body2" color="primary" gutterBottom>
          {successMessage}
        </Typography>
      )}

      <Box my={2}>
        <Grid container spacing={2}>
          {/* Informações do Titular */}
          <Grid item xs={12} md={6}>
            <TitularSelector
              value={quotationData.titular_cpf || quotationData.titular_cnpj}
              onChange={(titular) => setQuotationData({...quotationData, titular})}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              fullWidth
              label="Nº OS"
              name="numero_os"
              value={quotationData.numero_os || ''}
              disabled
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="Data de Criação"
              type="datetime-local"
              name="data_criacao"
              value={quotationData.data_criacao ? quotationData.data_criacao.split('.')[0] : ''}
              disabled
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Cliente"
              name="cliente"
              value={quotationData.cliente || ''}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>

          {/* Campos básicos */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Referência"
              name="referencia"
              value={quotationData.referencia || ''}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>

          {/* Seção de Serviços */}
          <Grid item xs={12}>
            <Typography variant="h6" className="sectionTitle">
              Serviços
            </Typography>
            {quotationData.servicos?.map((servico, index) => (
              <Box key={index} sx={{ display: 'flex', gap: 2, mb: 2 }}>
                <TextField
                  label="Descrição"
                  value={servico.descricao}
                  onChange={(e) => handleServiceChange(index, 'descricao', e.target.value)}
                  sx={{ flex: 2 }}
                />
                <TextField
                  label="Horas/Qtde"
                  type="number"
                  value={servico.quantidade}
                  onChange={(e) => handleServiceChange(index, 'quantidade', Number(e.target.value))}
                  sx={{ flex: 1 }}
                />
                <TextField
                  label="Valor Unitário"
                  type="number"
                  value={servico.preco_unitario}
                  onChange={(e) => handleServiceChange(index, 'preco_unitario', Number(e.target.value))}
                  sx={{ flex: 1 }}
                />
                <TextField
                  label="Valor Total"
                  value={formatCurrency(servico.quantidade * servico.preco_unitario)}
                  disabled
                  sx={{ flex: 1 }}
                />
                <IconButton onClick={() => removeService(index)} color="error">
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
            <Button startIcon={<AddIcon />} onClick={addNewService}>
              Adicionar Serviço
            </Button>
          </Grid>

          {/* Seção de Produtos */}
          <Grid item xs={12}>
            <Typography variant="h6" className="sectionTitle">
              Produtos
            </Typography>
            {quotationData.produtos?.map((produto, index) => (
              <Box key={index} sx={{ display: 'flex', gap: 2, mb: 2 }}>
                <TextField
                  label="Descrição"
                  value={produto.descricao}
                  onChange={(e) => handleProductChange(index, 'descricao', e.target.value)}
                  sx={{ flex: 2 }}
                />
                <TextField
                  label="Quantidade"
                  type="number"
                  value={produto.quantidade}
                  onChange={(e) => handleProductChange(index, 'quantidade', Number(e.target.value))}
                  sx={{ flex: 1 }}
                />
                <TextField
                  label="Valor Unitário"
                  type="number"
                  value={produto.preco_unitario}
                  onChange={(e) => handleProductChange(index, 'preco_unitario', Number(e.target.value))}
                  sx={{ flex: 1 }}
                />
                <TextField
                  label="Valor Total"
                  value={formatCurrency(produto.quantidade * produto.preco_unitario)}
                  disabled
                  sx={{ flex: 1 }}
                />
                <IconButton onClick={() => removeProduct(index)} color="error">
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
            <Button startIcon={<AddIcon />} onClick={addNewProduct}>
              Adicionar Produto
            </Button>
          </Grid>

          {/* Totais e Status */}
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              label="Total de Serviços"
              value={formatCurrency(
                quotationData.servicos?.reduce((total, servico) => 
                  total + (servico.quantidade * servico.preco_unitario), 0) || 0
              )}
              disabled
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              label="Total de Produtos"
              value={formatCurrency(
                quotationData.produtos?.reduce((total, produto) => 
                  total + (produto.quantidade * produto.preco_unitario), 0) || 0
              )}
              disabled
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              label="Desconto"
              name="desconto"
              type="number"
              value={quotationData.desconto || 0}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              label="Valor Total do Orçamento"
              value={formatCurrency(
                (quotationData.servicos?.reduce((total, servico) => 
                  total + (servico.quantidade * servico.preco_unitario), 0) || 0) +
                (quotationData.produtos?.reduce((total, produto) => 
                  total + (produto.quantidade * produto.preco_unitario), 0) || 0) -
                (quotationData.desconto || 0)
              )}
              disabled
              variant="outlined"
            />
          </Grid>

          {/* Campos adicionais */}
          <Grid item xs={12} md={6}>
            <TextField
              select
              fullWidth
              label="Status"
              name="status"
              value={quotationData.status || ''}
              onChange={handleChange}
              variant="outlined"
            >
              {statusOrcamento.map((option) => (
                <MenuItem key={option.valor} value={option.valor}>
                  {option.nome}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
          </Grid>

          {/* Observações */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Observações"
              name="observacoes"
              value={quotationData.observacoes || ''}
              onChange={handleChange}
              variant="outlined"
              multiline
              rows={4}
            />
          </Grid>

          {/* Informações do Titular */}
          {quotationData.titular_cpf && (
            <>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Nome do Titular"
                  value={titularName}
                  disabled
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="CPF do Titular"
                  value={quotationData.titular_cpf}
                  disabled
                  variant="outlined"
                />
              </Grid>
            </>
          )}
          {quotationData.titular_cnpj && (
            <>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Razão Social"
                  value={titularName}
                  disabled
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="CNPJ do Titular"
                  value={quotationData.titular_cnpj}
                  disabled
                  variant="outlined"
                />
              </Grid>
            </>
          )}

          {/* Botão Salvar */}
          {isChanged && (
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleSaveChanges}
              >
                Salvar Alterações
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </div>
  );
};

export default QuotationInfo;