import axios from 'axios';
import config from '../../config/apiConfig';
import {
  formatCpfForServer,
  formatCpfForDisplay,
  formatCnpjForServer,
  formatCnpjForDisplay,
  validateCpf,
  validateCnpj
} from '../../utils/helpers';

export const createAccount = async (accountData) => {

  const token = localStorage.getItem('authToken');

  // Limpa o campo titular para remover caracteres não numéricos
  const cleanIdentifier = accountData.titular.replace(/\D/g, '');

  let formattedCpf = null;
  let formattedCnpj = null;

  // Determina se o identificador é CPF ou CNPJ com base no número de dígitos
  if (cleanIdentifier.length === 11) {
    if (!validateCpf(cleanIdentifier)) {
      throw new Error('CPF inválido');
    }
    formattedCpf = formatCpfForServer(cleanIdentifier);
  } else if (cleanIdentifier.length === 14) {
    if (!validateCnpj(cleanIdentifier)) {
      throw new Error('CNPJ inválido');
    }
    formattedCnpj = formatCnpjForServer(cleanIdentifier);
  } else {
    throw new Error('Identificador inválido. Forneça um CPF ou CNPJ válido.');
  }

  // Verificação dos campos obrigatórios
  const requiredFields = ['numero_conta', 'digito_conta', 'agencia', 'tipo_conta', 'moeda', 'id_banco'];
  for (const field of requiredFields) {
    if (!accountData[field]) {
      throw new Error(`Campo obrigatório não preenchido: ${field}`);
    }
  }

  const jsonData = {
    ...accountData,
    titular_cpf: formattedCpf,
    titular_cnpj: formattedCnpj,
  };

  try {
    const response = await axios.post(`${config.apiUrl+config.financeApiUrl}/accounts`, jsonData, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    // Formatação dos dados para display
    const account = response.data;
    if (account.titular_cpf) {
      account.titular_cpf = formatCpfForDisplay(account.titular_cpf);
    }
    if (account.titular_cnpj) {
      account.titular_cnpj = formatCnpjForDisplay(account.titular_cnpj);
    }
    return account;
  } catch (error) {
    handleServiceError(error, 'Erro ao criar conta bancária.');
  }
};

export const updateAccount = async (accountID, updatedAccountData) => {
  const token = localStorage.getItem('authToken');

  // Verificação e formatação de CPF/CNPJ, se fornecido
  if (updatedAccountData.titular_cpf && validateCpf(updatedAccountData.titular_cpf)) {
    updatedAccountData.titular_cpf = formatCpfForServer(updatedAccountData.titular_cpf);
  }
  if (updatedAccountData.titular_cnpj && validateCnpj(updatedAccountData.titular_cnpj)) {
    updatedAccountData.titular_cnpj = formatCnpjForServer(updatedAccountData.titular_cnpj);
  }

  // Verificação de campos obrigatórios apenas se forem enviados
  const requiredFields = ['numero_conta', 'digito_conta', 'agencia', 'tipo_conta', 'moeda', 'id_banco'];
  for (const field of requiredFields) {
    if (updatedAccountData.hasOwnProperty(field) && !updatedAccountData[field]) {
      throw new Error(`Campo obrigatório não preenchido: ${field}`);
    }
  }

  try {
    const response = await axios.put(`${config.apiUrl+config.financeApiUrl}/accounts/${accountID}`, updatedAccountData, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    // Formatação dos dados para display
    const account = response.data;
    if (account.titular_cpf) {
      account.titular_cpf = formatCpfForDisplay(account.titular_cpf);
    }
    if (account.titular_cnpj) {
      account.titular_cnpj = formatCnpjForDisplay(account.titular_cnpj);
    }
    return account;
  } catch (error) {
    handleServiceError(error, 'Erro ao atualizar conta bancária.');
  }
};

export const getAccountByID = async (accountID) => {
  const token = localStorage.getItem('authToken');

  try {
    const response = await axios.get(`${config.apiUrl+config.financeApiUrl}/accounts/id/${accountID}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    // Formatação dos dados para display
    const account = response.data;
    if (account.titular_cpf) {
      account.titular_cpf = formatCpfForDisplay(account.titular_cpf);
    }
    if (account.titular_cnpj) {
      account.titular_cnpj = formatCnpjForDisplay(account.titular_cnpj);
    }
    return account;
  } catch (error) {
    handleServiceError(error, 'Erro ao buscar conta bancária.');
  }
};

export const getAccountByIdentifier = async (identifier) => {
  const token = localStorage.getItem('authToken');

  // Determina se o identificador é CPF ou CNPJ e formata para o servidor
  let formattedIdentifier;
  if (validateCpf(identifier)) {
    formattedIdentifier = formatCpfForServer(identifier);
  } else if (validateCnpj(identifier)) {
    formattedIdentifier = formatCnpjForServer(identifier);
  } else {
    throw new Error('Identificador inválido. Forneça um CPF ou CNPJ válido.');
  }

  try {
    const response = await axios.get(`${config.apiUrl+config.financeApiUrl}/accounts/${formattedIdentifier}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    // Formatação dos dados para display
    const accounts = response.data.map(account => {
      if (account.titular_cpf) {
        account.titular_cpf = formatCpfForDisplay(account.titular_cpf);
      }
      if (account.titular_cnpj) {
        account.titular_cnpj = formatCnpjForDisplay(account.titular_cnpj);
      }
      return account;
    });

    return accounts;
  } catch (error) {
    handleServiceError(error, 'Erro ao buscar conta bancária por CPF ou CNPJ.');
  }
};

// Função auxiliar para tratamento de erros
const handleServiceError = (error, defaultMessage) => {
  console.error(defaultMessage, error);
  if (error.response && error.response.data && error.response.data.error) {
    throw new Error(error.response.data.error);
  } else if (error.response && error.response.data) {
    throw new Error(error.response.data.message || defaultMessage);
  } else {
    throw new Error(defaultMessage);
  }
};
