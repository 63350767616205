import axios from 'axios';
import config from '../../config/apiConfig';
import { 
  formatCpfForServer, 
  formatCpfForDisplay, 
  formatCepForServer, 
  formatDateForServer, 
  validateCpf, 
  validateCep, 
  validateDate, 
  formatProfileData,
  formatCnpjForDisplay,
  formatDateForDisplay,
  formatCepForDisplay
} from '../../utils/helpers';

// Funções de Person

export const getProfileDataById = async (userId) => {
  const token = localStorage.getItem('authToken');
  const url = `${config.apiUrl+config.profileApiUrl}/person/${userId}`;
  try {
    const response = await axios.get(url, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return formatProfileData(response.data);
  } catch (error) {
    console.error('Erro ao buscar dados do perfil pelo ID:', error);
    throw error;
  }
};

export const getProfileDataByCpf = async (cpf) => {
  const token = localStorage.getItem('authToken');
  const formattedCpf = formatCpfForServer(cpf);
  if (!formattedCpf) {
    throw new Error('CPF inválido');
  }

  const url = `${config.apiUrl+config.profileApiUrl}/person/${formattedCpf}`;

  try {
    const response = await axios.get(url, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    return formatProfileData(response.data);
  } catch (error) {
    console.error('Erro ao buscar dados do perfil pelo CPF:', error);
    throw error;
  }
};

export const updateProfileData = async (cpf, updatedFields) => {
  const token = localStorage.getItem('authToken');

  // Verifica se o CPF fornecido na função é válido e formata para o servidor
  if (!validateCpf(cpf)) {
    throw new Error('CPF inválido');
  }
  const formattedCpf = formatCpfForServer(cpf);

  // Verifica se o campo CPF está sendo atualizado
  if (updatedFields.cpf) {
    if (!validateCpf(updatedFields.cpf)) {
      throw new Error('CPF inválido');
    }
    updatedFields.cpf = formatCpfForServer(updatedFields.cpf);
  }

  // Verifica se o campo CEP está sendo atualizado
  if (updatedFields.cep) {
    if (!validateCep(updatedFields.cep)) {
      throw new Error('CEP inválido');
    }
    updatedFields.cep = formatCepForServer(updatedFields.cep);
  }

  // Verifica se o campo data de nascimento está sendo atualizado
  if (updatedFields.data_nascimento) {
    if (!validateDate(updatedFields.data_nascimento)) {
      throw new Error('Data de nascimento inválida');
    }
    updatedFields.data_nascimento = formatDateForServer(updatedFields.data_nascimento);
  }

  const url = `${config.apiUrl+config.profileApiUrl}/person/${formattedCpf}`;
  try {
    const response = await axios.put(url, updatedFields, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });

    // Tratar as informações de retorno para exibição
    const formattedData = {
      ...response.data,
      cpf: updatedFields.cpf ? formatCpfForDisplay(updatedFields.cpf) : response.data.cpf,
      cep: updatedFields.cep ? formatCepForDisplay(updatedFields.cep) : response.data.cep,
      data_nascimento: updatedFields.data_nascimento ? formatDateForDisplay(updatedFields.data_nascimento) : response.data.data_nascimento,
    };

    localStorage.setItem('hasPersonalData', 'true');
    console.log('Dados atualizados com sucesso no servidor.');

    return formattedData;
  } catch (error) {
    console.error('Erro ao atualizar os dados pessoais:', error);
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error); 
    } else {
      throw new Error('Erro ao atualizar os dados pessoais');
    }
  }
};

export const createPerson = async (personData) => {
  const token = localStorage.getItem('authToken');

  // Validar e formatar os dados
  if (!validateCpf(personData.cpf)) {
    throw new Error('CPF inválido');
  }
  personData.cpf = formatCpfForServer(personData.cpf);

  if (!validateDate(personData.data_nascimento)) {
    throw new Error('Data de nascimento inválida');
  }
  personData.data_nascimento = formatDateForServer(personData.data_nascimento);

  personData.cep = formatCepForServer(personData.cep);

  const url = `${config.apiUrl+config.profileApiUrl}/person`;
  try {
    const response = await axios.post(url, personData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });

    // Formatar os dados retornados
    return formatProfileData(response.data);
  } catch (error) {
    console.error('Erro ao criar os dados pessoais:', error);
    throw new Error(error.response?.data?.error || 'Erro ao criar os dados pessoais');
  }
};

// Funções de Profile

export const getUserData = async () => {
  const token = localStorage.getItem('authToken');
  try {
    const response = await axios.get(`${config.apiUrl+config.profileApiUrl}/users/profile/`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    // Formatar os CPFs e CNPJs retornados
    const formattedProfile = {
      ...response.data,
      cpf_usuario: formatCpfForDisplay(response.data.cpf_usuario),
      perfil_compartilhado: response.data.perfil_compartilhado.map(cpf => formatCpfForDisplay(cpf)),
      membro_empresa: response.data.membro_empresa.map(cnpj => formatCnpjForDisplay(cnpj)),
    };

    // Armazenar os CPFs e o email formatados no localStorage
    localStorage.setItem('userCpf', formattedProfile.cpf_usuario);
    localStorage.setItem('userEmail', formattedProfile.email);

    // Retorna os dados formatados
    return formattedProfile;
  } catch (error) {
    console.error('Erro ao buscar dados do usuário:', error);
    return null;
  }
};

export const shareUserAccess = async (cpf, onShareSuccess, handleClose, setError) => {
  const token = localStorage.getItem('authToken');
  const formattedCpf = formatCpfForServer(cpf); // Formatar CPF para servidor
  const url = `${config.apiUrl+config.profileApiUrl}/users/cpfs`;
  const body = { cpf: formattedCpf };

  try {
    const response = await axios.post(url, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });

    // Formatar o cpf_usuario retornado
    const formattedCpfUsuario = formatCpfForDisplay(response.data.cpf_usuario);

    onShareSuccess(formattedCpfUsuario);
    handleClose();
  } catch (error) {
    setError(error.response?.data?.error || 'Erro ao compartilhar perfil');
  }
};

export const removeUserAccess = async (cpf, profileAccess, setProfileAccess, handleCloseDeleteDialog) => {
  const token = localStorage.getItem('authToken');
  const formattedCpf = formatCpfForServer(cpf); // Formatar CPF para servidor
  const url = `${config.apiUrl+config.profileApiUrl}/users/cpfs`;

  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    data: { cpf: formattedCpf },
  };

  try {
    await axios(url, requestOptions);
    setProfileAccess(profileAccess.filter(profile => profile.cpf_usuario !== formattedCpf));
    handleCloseDeleteDialog();
  } catch (error) {
    console.error('Erro ao remover acesso ao perfil:', error);
    alert('Erro ao remover acesso ao perfil');
  }
};

export const getUsersWithAccess = async (cpf) => {
  const token = localStorage.getItem('authToken');
  const formattedCpf = formatCpfForServer(cpf); // Formatar CPF para servidor
  const url = `${config.apiUrl+config.profileApiUrl}/users/cpfs/${formattedCpf}`;
  
  try {
    const response = await axios.get(url, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    // Formatar CPFs para exibição
    const usersWithAccess = response.data.map(user => ({
      ...user,
      cpf_usuario: formatCpfForDisplay(user.cpf_usuario),
    }));

    return usersWithAccess;
  } catch (error) {
    console.error('Erro ao buscar usuários com acesso ao perfil:', error);
    throw new Error(error.response?.data?.error || 'Erro ao buscar usuários com acesso ao perfil');
  }
};

export const getUserTeamMember = async () => {
  const token = localStorage.getItem('authToken');
  const url = `${config.apiUrl+config.profileApiUrl}/users/cnpjs`;
  try {
    const response = await axios.get(url, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    // Formatando cada CNPJ para exibição
    const formattedCnpjs = response.data.map(cnpj => formatCnpjForDisplay(cnpj));

    return formattedCnpjs;
  } catch (error) {
    console.error('Erro ao buscar CNPJs do usuário:', error);
    throw new Error(error.response?.data?.error || 'Erro ao buscar CNPJs do usuário');
  }
};

// Funções de Imagem de Perfil

export const changeFile = (event, setSelectedFile) => {
  const file = event.target.files[0];
  setSelectedFile(file);
};

export const uploadUserImage = async (selectedFile) => {
  const token = localStorage.getItem('authToken');

  if (!selectedFile) {
    throw new Error('Nenhum arquivo selecionado.');
  }

  const formData = new FormData();
  formData.append('image', selectedFile);

  try {
    const { data } = await axios.post(`${config.apiUrl+config.profileApiUrl}/person/image`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    return data.profile_image;
  } catch (error) {
    const errorMessage = error.response?.data?.error || 'Erro ao atualizar a imagem de perfil';
    throw new Error(errorMessage);
  }
};