import axios from 'axios';
import config from '../../config/apiConfig';

export const getCardBrandList = async () => {
  const token = localStorage.getItem('authToken');

  try {
    const response = await axios.get(`${config.apiUrl+config.financeApiUrl}/cardBrands/`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    handleServiceError(error, 'Erro ao buscar informações das bandeiras de cartão.');
  }
};

export const getCardBrandById = async (id) => {
  const token = localStorage.getItem('authToken');

  try {
    const response = await axios.get(`${config.apiUrl+config.financeApiUrl}/cardBrands/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    handleServiceError(error, 'Erro ao buscar informações da bandeira de cartão.');
  }
};

const handleServiceError = (error, defaultMessage) => {
  console.error(defaultMessage, error);
  if (error.response && error.response.data && error.response.data.error) {
    throw new Error(error.response.data.error);
  } else if (error.response && error.response.data) {
    throw new Error(error.response.data.message || defaultMessage);
  } else {
    throw new Error(defaultMessage);
  }
};