import React, { useRef, useState, useEffect } from 'react';
import './header.css';
import Avatar from './avatar';
import ProfileSelector from './profileSelector';
import Notifications from './notifications';
import AiAssistant from './aiAssistant'; // Importando o AI Assistant

const Header = () => {
  const [isProfileSelectorOpen, setProfileSelectorOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);

  const profileSelectorRef = useRef(null);
  const notificationsRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      profileSelectorRef.current &&
      !profileSelectorRef.current.contains(event.target)
    ) {
      setProfileSelectorOpen(false);
    }
    if (
      notificationsRef.current &&
      !notificationsRef.current.contains(event.target)
    ) {
      setIsNotificationsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="globalHeader">
      <div className="globalHeader__left">
        <div ref={profileSelectorRef} className="globalHeader__profileSelector">
          <ProfileSelector
            isOpen={isProfileSelectorOpen}
            setIsOpen={setProfileSelectorOpen}
          />
        </div>
      </div>
      <div className="globalHeader__right">
        <AiAssistant /> {/* Integrando o AI Assistant */}
        <div ref={notificationsRef} className="globalHeader__notifications">
          <Notifications
            isOpen={isNotificationsOpen}
            setIsOpen={setIsNotificationsOpen}
          />
        </div>
        <Avatar />
      </div>
    </header>
  );
};

export default Header;
