import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Typography } from '@mui/material';
import { updateProfileData } from '../../../../services/profile/userProfileService';

const EditNameModal = ({ open, handleClose, profileData, setProfileData }) => {
  const [name, setName] = useState(profileData.nome);
  const [surname, setSurname] = useState(profileData.sobrenome);
  const [error, setError] = useState('');

  const handleSave = async () => {
    const updatedFields = {
      nome: name,
      sobrenome: surname,
    };

    try {
      const updatedProfile = await updateProfileData(profileData.cpf, updatedFields);
      setProfileData(prevData => ({
        ...prevData,
        nome: updatedProfile.nome,
        sobrenome: updatedProfile.sobrenome,
      }));
      handleClose();
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Editar Nome</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Nome"
          type="text"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          margin="dense"
          id="surname"
          label="Sobrenome"
          type="text"
          fullWidth
          value={surname}
          onChange={(e) => setSurname(e.target.value)}
        />
        {error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleSave} color="primary">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditNameModal;
