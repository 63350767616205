import { createTheme } from '@mui/material/styles';
import { GlobalStyles } from '@mui/system';
import { ptBR } from '@mui/material/locale';

const theme = createTheme({
  palette: {
    primary: {
      main: '#fca22d',
    },
    secondary: {
      main: '#ffffff',
    },
    background: {
      default: '#1c1c1c',
      paper: '#333',
    },
    text: {
      primary: '#ffffff',
      secondary: 'rgba(255, 255, 255, 0.7)',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h1: {
      fontSize: '2rem',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '1.75rem',
      fontWeight: 'bold',
    },
    body1: {
      fontSize: '1rem',
    },
    button: {
      textTransform: 'none',
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'rgba(255, 255, 255, 0.7)',
          '&.Mui-focused': {
            color: '#ffffff',
          },
          '&.Mui-disabled': {
            color: 'rgba(255, 255, 255, 0.5)',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#333',
          borderRadius: '30px',
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          color: '#ffffff',
          '&.Mui-disabled': {
            backgroundColor: '#555',
            color: 'rgba(255, 255, 255, 0.5)',
            borderColor: '#888',
          },
        },
        notchedOutline: {
          borderColor: 'transparent',
        },
        input: {
          '&.Mui-disabled': {
            WebkitTextFillColor: 'rgba(255, 255, 255, 0.5)',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: '#ffffff',
          '&:focus': {
            color: '#ffffff',
          },
          '&.Mui-disabled': {
            color: 'rgba(255, 255, 255, 0.5)',
            WebkitTextFillColor: 'rgba(255, 255, 255, 0.5)',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '30px',
        },
        contained: {
          backgroundColor: '#f8b133',
          '&:hover': {
            backgroundColor: '#e59429',
          },
        },
        outlined: {
          backgroundColor: 'rgba(255, 255, 255, 0.8)', // Fundo semitransparente
          color: '#000000',
          borderColor: 'rgba(255, 255, 255, 0.8)', // Borda semitransparente
          '&:hover': {
            backgroundColor: 'rgba(224, 224, 224, 0.8)', // Fundo hover semitransparente
            borderColor: 'rgba(224, 224, 224, 0.8)', // Borda hover semitransparente
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: '16px',
          backgroundColor: '#333',
          color: '#ffffff',
          borderRadius: '8px',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: '#1c1c1c', // Fundo mais escuro para o modal
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          backgroundColor: '#1c1c1c', // Fundo mais escuro para o conteúdo do modal
          color: '#ffffff',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: '0 !important',
          margin: '0 !important',
          backgroundColor: '#1c1c1c',
          color: '#ffffff',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: '240px',
          backgroundColor: '#0d0d0d',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          textAlign: 'left',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)', // Fundo mais escuro
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#555',
            color: '#ffffff',
            '& .MuiTableCell-root': {
              color: '#ffffff',
            },
            '&:hover': {
              backgroundColor: '#666',
            },
            '& .MuiIconButton-root': {
              color: '#ffffff',
            },
          },
        },
      },
    },
  },
}, ptBR);

const globalStyles = (
  <GlobalStyles
    styles={{
      a: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
        '&:visited': {
          color: theme.palette.primary.main,
        },
      },
    }}
  />
);

export { theme, globalStyles };
