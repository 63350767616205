// src/modules/erp/productCategory/productCategory.js

import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';

import './productAttributes.css';
import { ProfileContext } from '../../../context/profileContext';
import withAuth from '../../../hooks/withAuth';

import Sidebar from '../../../components/sidebar';
import Header from '../../../components/header';
import ProductCategoriesCard from './cards/productCategoriesCard';

const ProductAttributes = () => {
  const { selectedProfiles } = useContext(ProfileContext);

  return (
    <div className="productCategoriesContainer">
      <div className="sidebarContainer">
        <Sidebar />
      </div>
      <div className="contentContainer">
        <Header />
        <div className="productCategoriesContent">
          <Box mt={4}>
            <Typography variant="h4" align="left" gutterBottom>
              Gestão de Categorias de Produtos
            </Typography>
          </Box>
          {selectedProfiles.length > 0 ? (
            <ProductCategoriesCard />
          ) : (
            <Typography variant="body1">
              Selecione um perfil para visualizar as categorias associadas.
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default withAuth(ProductAttributes);