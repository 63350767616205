import axios from 'axios';
import config from '../../config/apiConfig';
import {
  formatCpfForServer,
  formatCpfForDisplay,
  formatCnpjForServer,
  formatCnpjForDisplay,
  validateCpf,
  validateCnpj
} from '../../utils/helpers';

// Função auxiliar para tratamento de erros
const handleServiceError = (error, defaultMessage) => {
  console.error(defaultMessage, error);
  
  if (error.response?.data?.error) {
    throw new Error(error.response.data.error);
  }
  
  if (error instanceof Error) {
    throw error;
  }
  
  throw new Error(defaultMessage);
};

export const createQuotation = async (quotationData) => {
  const token = localStorage.getItem('authToken');

  try {
    // Limpa o campo titular para remover caracteres não numéricos
    const cleanIdentifier = quotationData.titular.replace(/\D/g, '');

    let formattedCpf = null;
    let formattedCnpj = null;

    // Determina se o identificador é CPF ou CNPJ com base no número de dígitos
    if (cleanIdentifier.length === 11) {
      if (!validateCpf(cleanIdentifier)) {
        throw new Error('CPF inválido');
      }
      formattedCpf = formatCpfForServer(cleanIdentifier);
    } else if (cleanIdentifier.length === 14) {
      if (!validateCnpj(cleanIdentifier)) {
        throw new Error('CNPJ inválido');
      }
      formattedCnpj = formatCnpjForServer(cleanIdentifier);
    } else {
      throw new Error('Titular não foi selecionado. Por favor, selecione um titular.');
    }

    // Validação dos produtos e serviços
    const produtosValidos = quotationData.produtos.filter(produto => 
      produto.descricao || produto.quantidade || produto.preco_unitario
    );
    
    const servicosValidos = quotationData.servicos.filter(servico => 
      servico.descricao || servico.quantidade || servico.preco_unitario
    );

    // Verifica se tem pelo menos um produto OU um serviço
    if (produtosValidos.length === 0 && servicosValidos.length === 0) {
      throw new Error('É necessário incluir pelo menos um produto ou serviço');
    }

    // Valida se todos os campos estão preenchidos para produtos com algum campo preenchido
    produtosValidos.forEach(produto => {
      if (!produto.descricao || !produto.quantidade || !produto.preco_unitario) {
        throw new Error('Todos os campos do produto são obrigatórios quando a linha está sendo utilizada');
      }
      if (produto.quantidade <= 0) {
        throw new Error('A quantidade do produto deve ser maior que zero');
      }
      if (produto.preco_unitario < 0) {
        throw new Error('O preço unitário do produto não pode ser negativo');
      }
    });

    // Valida se todos os campos estão preenchidos para serviços com algum campo preenchido
    servicosValidos.forEach(servico => {
      if (!servico.descricao || !servico.quantidade || !servico.preco_unitario) {
        throw new Error('Todos os campos do serviço são obrigatórios quando a linha está sendo utilizada');
      }
      if (servico.quantidade <= 0) {
        throw new Error('A quantidade do serviço deve ser maior que zero');
      }
      if (servico.preco_unitario < 0) {
        throw new Error('O preço unitário do serviço não pode ser negativo');
      }
    });

    // Atualiza os arrays no quotationData para incluir apenas itens válidos
    quotationData.produtos = produtosValidos;
    quotationData.servicos = servicosValidos;

    const jsonData = {
      ...quotationData,
      titular_cpf: formattedCpf,
      titular_cnpj: formattedCnpj,
    };
    delete jsonData.titular; // Remove o campo titular original

    const response = await axios.post(`${config.apiUrl + config.salesApiUrl}/quotations`, jsonData, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    const quotation = response.data;
    if (quotation.titular_cpf) {
      quotation.titular_cpf = formatCpfForDisplay(quotation.titular_cpf);
    }
    if (quotation.titular_cnpj) {
      quotation.titular_cnpj = formatCnpjForDisplay(quotation.titular_cnpj);
    }
    return quotation;
  } catch (error) {
    handleServiceError(error, 'Erro ao criar orçamento.');
  }
};

export const updateQuotation = async (quotationId, updatedQuotationData) => {
  const token = localStorage.getItem('authToken');

  try {
    // Validação e formatação de CPF/CNPJ, se fornecido
    if (updatedQuotationData.titular_cpf) {
      if (!validateCpf(updatedQuotationData.titular_cpf)) {
        throw new Error('CPF inválido');
      }
      updatedQuotationData.titular_cpf = formatCpfForServer(updatedQuotationData.titular_cpf);
    }
    if (updatedQuotationData.titular_cnpj) {
      if (!validateCnpj(updatedQuotationData.titular_cnpj)) {
        throw new Error('CNPJ inválido');
      }
      updatedQuotationData.titular_cnpj = formatCnpjForServer(updatedQuotationData.titular_cnpj);
    }

    // Nova lógica de validação para produtos e serviços
    if (updatedQuotationData.produtos) {
      const produtosValidos = updatedQuotationData.produtos.filter(produto => 
        produto.descricao || produto.quantidade || produto.preco_unitario
      );

      produtosValidos.forEach(produto => {
        if (!produto.descricao || !produto.quantidade || !produto.preco_unitario) {
          throw new Error('Todos os campos do produto são obrigatórios quando a linha está sendo utilizada');
        }
        if (produto.quantidade <= 0) {
          throw new Error('A quantidade do produto deve ser maior que zero');
        }
        if (produto.preco_unitario < 0) {
          throw new Error('O preço unitário do produto não pode ser negativo');
        }
      });

      updatedQuotationData.produtos = produtosValidos;
    }

    if (updatedQuotationData.servicos) {
      const servicosValidos = updatedQuotationData.servicos.filter(servico => 
        servico.descricao || servico.quantidade || servico.preco_unitario
      );

      servicosValidos.forEach(servico => {
        if (!servico.descricao || !servico.quantidade || !servico.preco_unitario) {
          throw new Error('Todos os campos do serviço são obrigatórios quando a linha está sendo utilizada');
        }
        if (servico.quantidade <= 0) {
          throw new Error('A quantidade do serviço deve ser maior que zero');
        }
        if (servico.preco_unitario < 0) {
          throw new Error('O preço unitário do serviço não pode ser negativo');
        }
      });

      updatedQuotationData.servicos = servicosValidos;
    }

    // Verifica se tem pelo menos um produto OU um serviço
    const temProdutos = updatedQuotationData.produtos?.length > 0;
    const temServicos = updatedQuotationData.servicos?.length > 0;
    
    if (!temProdutos && !temServicos) {
      throw new Error('É necessário incluir pelo menos um produto ou serviço');
    }

    const response = await axios.put(`${config.apiUrl + config.salesApiUrl}/quotations/${quotationId}`, updatedQuotationData, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    const quotation = response.data;
    if (quotation.titular_cpf) {
      quotation.titular_cpf = formatCpfForDisplay(quotation.titular_cpf);
    }
    if (quotation.titular_cnpj) {
      quotation.titular_cnpj = formatCnpjForDisplay(quotation.titular_cnpj);
    }
    return quotation;
  } catch (error) {
    handleServiceError(error, 'Erro ao atualizar orçamento.');
  }
};

export const getQuotationById = async (quotationId) => {
  const token = localStorage.getItem('authToken');

  try {
    const response = await axios.get(`${config.apiUrl + config.salesApiUrl}/quotations/${quotationId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    const quotation = response.data;
    if (quotation.titular_cpf) {
      quotation.titular_cpf = formatCpfForDisplay(quotation.titular_cpf);
    }
    if (quotation.titular_cnpj) {
      quotation.titular_cnpj = formatCnpjForDisplay(quotation.titular_cnpj);
    }
    return quotation;
  } catch (error) {
    handleServiceError(error, 'Erro ao buscar orçamento.');
  }
};

export const getQuotationsByIdentifier = async (identifier) => {
  const token = localStorage.getItem('authToken');

  try {
    // Limpa o identificador de caracteres não numéricos
    const cleanIdentifier = identifier.replace(/\D/g, '');
    let queryParams = {};

    // Determina se é CPF ou CNPJ e formata adequadamente
    if (cleanIdentifier.length === 11) {
      if (!validateCpf(cleanIdentifier)) {
        throw new Error('CPF inválido');
      }
      queryParams.titular_cpf = formatCpfForServer(cleanIdentifier);
    } else if (cleanIdentifier.length === 14) {
      if (!validateCnpj(cleanIdentifier)) {
        throw new Error('CNPJ inválido');
      }
      queryParams.titular_cnpj = formatCnpjForServer(cleanIdentifier);
    } else {
      throw new Error('Identificador inválido. Forneça um CPF ou CNPJ válido.');
    }

    const response = await axios.get(`${config.apiUrl + config.salesApiUrl}/quotations`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      params: queryParams,
    });

    const quotations = response.data.map(quotation => {
      if (quotation.titular_cpf) {
        quotation.titular_cpf = formatCpfForDisplay(quotation.titular_cpf);
      }
      if (quotation.titular_cnpj) {
        quotation.titular_cnpj = formatCnpjForDisplay(quotation.titular_cnpj);
      }
      return quotation;
    });

    return quotations;
  } catch (error) {
    handleServiceError(error, 'Erro ao buscar orçamentos por CPF ou CNPJ.');
  }
};

export const deleteQuotation = async (quotationId) => {
  const token = localStorage.getItem('authToken');

  try {
    const response = await axios.delete(`${config.apiUrl + config.salesApiUrl}/quotations/${quotationId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    handleServiceError(error, 'Erro ao excluir orçamento.');
  }
};
