import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Typography, Grid, Box, Checkbox, FormControlLabel, IconButton, InputAdornment, Button } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import './login.css';
import loginImage from '../../img/img1.png';
import { login } from '../../services/auth/authService';
import config from '../../config/apiConfig'; // Importando as configurações

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      navigate(config.loginRedirectUrl);
    }
  }, [navigate]);

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await login(email, password, navigate);
      // Não é necessário redirecionar aqui, pois o redirecionamento
      // será feito dentro da função login se necessário
    } catch (error) {
      console.error('Erro ao fazer login:', error);
      setErrorMessage(error.message);
    }
  };

  return (
    <Container maxWidth="lg" className="container">
      <Grid container spacing={0} alignItems="center">
        <Grid item xs={12} md={6} className="imageContainer" style={{ backgroundImage: `url(${loginImage})` }} />
        <Grid item xs={12} md={6} className="loginForm">
          <Box>
            <Typography variant="h4" component="h1" gutterBottom>
              EI Mona - Login
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Faça login para gerenciar suas finanças
            </Typography>
            {errorMessage && (
              <Typography variant="body2" color="error" gutterBottom>
                {errorMessage}
              </Typography>
            )}
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <TextField
                fullWidth
                margin="normal"
                label="Digite seu endereço de e-mail"
                variant="outlined"
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <MailIcon style={{ color: '#ffffff' }} />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Digite sua senha"
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        style={{ color: '#ffffff' }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Box display="flex" justifyContent="flex-start" width="100%">
                <FormControlLabel
                  control={<Checkbox name="remember" color="primary" />}
                  label="Lembrar-me"
                />
              </Box>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                style={{ marginTop: '1rem', borderRadius: '30px' }}
              >
                Entrar
              </Button>
              <Typography variant="body2" className="forgotPassword">
                <a href="/">Esqueceu sua senha?</a>
              </Typography>
              <Box className="register">
                <Typography variant="body2">
                  Criar uma conta <a href="/signup">Registrar-se</a>
                </Typography>
              </Box>
              <Box className="socialButtonsContainer">
                <button className="socialButton google">
                  <GoogleIcon style={{ marginRight: '8px' }} />
                  Google
                </button>
                <button className="socialButton facebook">
                  <FacebookIcon style={{ marginRight: '8px' }} />
                  Facebook
                </button>
                <button className="socialButton twitter">
                  <TwitterIcon style={{ marginRight: '8px' }} />
                  Twitter
                </button>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;
