import React, { useState, useEffect } from 'react';
import { TextField, Typography, IconButton, Button, Stack, Box, MenuItem } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import { estados } from '../../../../config/constants';
import config from '../../../../config/apiConfig';
import { getInitials, formatCepForServer, fetchAddressByCep } from '../../../../utils/helpers';
import EditCompanyNameModal from './editCompanyName';
import EditCompanyLogoModal from './editCompanyLogo'; // Adicione esta linha
import { getCompanyData, updateCompany } from '../../../../services/profile/companyProfileService';

const CompanyInfoCard = ({ cnpj }) => {
  const [companyData, setCompanyData] = useState({
    logo_image: '',
    razao_social: '',
    cnpj: '',
    inscricao_estadual: '',
    inscricao_municipal: '',
    cep: '',
    cidade: '',
    estado: '',
    logradouro: '',
    bairro: '',
    numero: '',
    complemento: '',
    telefone: '',
    cargo_responsavel: '',
    setor_atividade: '',
    tipo_empresa: '',
    numero_funcionarios: '',
    cpf_responsavel: '',
    nome_responsavel: ''
  });
  const [originalCompanyData, setOriginalCompanyData] = useState({});
  const [openEditCompanyNameModal, setOpenEditCompanyNameModal] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [cepError, setCepError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');  // Estado para a mensagem de erro
  const [successMessage, setSuccessMessage] = useState('');  // Estado para a mensagem de sucesso
const [openLogoModal, setOpenLogoModal] = useState(false);
const handleOpenLogoModal = () => setOpenLogoModal(true);
const handleCloseLogoModal = () => setOpenLogoModal(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getCompanyData(cnpj);
        setCompanyData({
          ...data,
          logo_image: data.logo_image || '',
          razao_social: data.razao_social || '',
          cnpj: data.cnpj || '',
          inscricao_estadual: data.inscricao_estadual || '',
          inscricao_municipal: data.inscricao_municipal || '',
          cep: data.cep || '',
          cidade: data.cidade || '',
          estado: data.estado || '',
          logradouro: data.logradouro || '',
          bairro: data.bairro || '',
          numero: data.numero || '',
          complemento: data.complemento || '',
          telefone: data.telefone || '',
          cargo_responsavel: data.cargo_responsavel || '',
          setor_atividade: data.setor_atividade || '',
          tipo_empresa: data.tipo_empresa || '',
          numero_funcionarios: data.numero_funcionarios || '',
          cpf_responsavel: data.cpf_responsavel || '',
          nome_responsavel: data.nome_responsavel || ''
        });
        setOriginalCompanyData(data);
      } catch (error) {
        console.error('Erro ao buscar dados da empresa:', error);
        setErrorMessage('Erro ao carregar os dados da empresa.');
      }
    };

    fetchData();
  }, [cnpj]);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setCompanyData(prevData => {
        const updatedData = { ...prevData, [name]: value };

        if (originalCompanyData) {
            const isChanged = Object.keys(updatedData).some(key => updatedData[key] !== originalCompanyData[key]);
            setIsDataChanged(isChanged);
        }

        return updatedData;
    });

    if (name === 'cep') {
        const cleanCep = formatCepForServer(value);

        if (cleanCep) {
            try {
                const addressData = await fetchAddressByCep(cleanCep);
                if (addressData.erro) {
                    setCepError('CEP inválido');
                } else {
                    setCompanyData(prevData => ({
                        ...prevData,
                        logradouro: addressData.logradouro,
                        bairro: addressData.bairro,
                        cidade: addressData.localidade,
                        estado: addressData.uf,
                        numero: '', // Limpa o campo número
                        complemento: '' // Limpa o campo complemento
                    }));
                    setCepError('');
                }
            } catch (error) {
                setCepError('Erro ao buscar endereço');
            }
        } else {
            setCepError('');
        }
    }
};


  const handleEdit = () => {
    setOpenEditCompanyNameModal(true);
  };

  const handleCloseEditCompanyNameModal = () => {
    setOpenEditCompanyNameModal(false);
  };

  const handleSave = async () => {
    const changedData = Object.keys(companyData).reduce((acc, key) => {
      if (companyData[key] !== originalCompanyData[key]) {
        acc[key] = companyData[key];
      }
      return acc;
    }, {});

    if (Object.keys(changedData).length > 0) {
      try {
        await updateCompany(cnpj, changedData);
        setOriginalCompanyData({ ...originalCompanyData, ...changedData });
        setIsDataChanged(false);
        setSuccessMessage('Dados atualizados com sucesso!');
        setErrorMessage('');  // Limpa a mensagem de erro
      } catch (error) {
        console.error('Erro ao atualizar os dados da empresa:', error);
        const serverMessage = error.response?.data?.error || error.message || 'Erro ao atualizar os dados da empresa.';
        setErrorMessage(serverMessage);
        setSuccessMessage('');  // Limpa a mensagem de sucesso
      }
    }
  };

  if (!companyData) {
    return <Typography variant="body2">Carregando dados da empresa...</Typography>;
  }

  const isEditable = localStorage.getItem('userCpf') === companyData.cpf_responsavel;

   return (
    <div className="companyCard">
      <div className="companyHeader">
        <div className="companyAvatarContainer" onClick={isEditable ? handleOpenLogoModal : null}>
          {companyData.logo_image ? (
            <img src={`${config.serverUrl}${companyData.logo_image}`} alt="Company Logo" className="companyAvatar" />
          ) : (
            <div className="companyAvatarPlaceholder">
              {getInitials(companyData.razao_social)}
            </div>
          )}
          {isEditable && <div className="editAvatar">Clique para alterar</div>}
        </div>
        <div className="companyName">
          <Typography variant="h6" display="inline">{companyData.razao_social}</Typography>
          {isEditable && (
            <IconButton onClick={handleEdit}>
              <Edit />
            </IconButton>
          )}
        </div>
      </div>

      {/* Espaço para exibir a mensagem de erro ou sucesso */}
      {errorMessage && (
        <Typography variant="body2" color="error" gutterBottom>
          {errorMessage}
        </Typography>
      )}
      {successMessage && (
        <Typography variant="body2" color="primary" gutterBottom>
          {successMessage}
        </Typography>
      )}
      <div className="profileInfo">
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              label="CNPJ"
              variant="outlined"
              value={companyData.cnpj}
              name="cnpj"
              disabled
              className="textFieldReadOnly"
              sx={{ flex: 1 }} // Adicionando esta linha para ocupar metade da linha
            />
            <Box sx={{ flex: 1 }} /> {/* Espaço em branco para ocupar a outra metade */}
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              label="Inscrição Estadual"
              variant="outlined"
              value={companyData.inscricao_estadual}
              name="inscricao_estadual"
              disabled
              className="textFieldReadOnly"
            />
            <TextField
              fullWidth
              label="Inscrição Municipal"
              variant="outlined"
              value={companyData.inscricao_municipal}
              name="inscricao_municipal"
              disabled
              className="textFieldReadOnly"
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              label="CEP"
              variant="outlined"
              value={companyData.cep}
              name="cep"
              onChange={isEditable ? handleChange : undefined}
              disabled={!isEditable}
              error={!!cepError}
              helperText={isEditable ? (cepError || "Ao digitar o CEP, preencheremos automaticamente os campos equivalentes.") : ''}
              sx={{ flex: 1 }} // Adicionando esta linha para ocupar metade da linha
            />
            <Box flexGrow={1} /> {/* Espaço flexível */}
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              label="Cidade"
              variant="outlined"
              value={companyData.cidade}
              name="cidade"
              onChange={isEditable ? handleChange : undefined}
              disabled={!isEditable}
            />
            <TextField
              select
              fullWidth
              label="Estado"
              variant="outlined"
              name="estado"
              value={companyData.estado}
              onChange={isEditable ? handleChange : undefined}
              disabled={!isEditable}
            >
              {estados.map((estado) => (
                <MenuItem key={estado.sigla} value={estado.sigla}>
                  {estado.nome}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          <TextField
            fullWidth
            label="Logradouro"
            variant="outlined"
            value={companyData.logradouro}
            name="logradouro"
            onChange={isEditable ? handleChange : undefined}
            disabled={!isEditable}
          />
          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              label="Número"
              variant="outlined"
              value={companyData.numero}
              name="numero"
              onChange={isEditable ? handleChange : undefined}
              disabled={!isEditable}
            />
            <TextField
              fullWidth
              label="Complemento"
              variant="outlined"
              value={companyData.complemento}
              name="complemento"
              onChange={isEditable ? handleChange : undefined}
              disabled={!isEditable}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              label="Bairro"
              variant="outlined"
              value={companyData.bairro}
              name="bairro"
              onChange={isEditable ? handleChange : undefined}
              disabled={!isEditable}
            />
            <TextField
              fullWidth
              label="Setor de Atividade"
              variant="outlined"
              value={companyData.setor_atividade}
              name="setor_atividade"
              onChange={isEditable ? handleChange : undefined}
              disabled={!isEditable}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              label="Tipo de Empresa"
              variant="outlined"
              value={companyData.tipo_empresa}
              name="tipo_empresa"
              onChange={isEditable ? handleChange : undefined}
              disabled={!isEditable}
            />
            <TextField
              fullWidth
              label="Número de Funcionários"
              variant="outlined"
              value={companyData.numero_funcionarios}
              name="numero_funcionarios"
              onChange={isEditable ? handleChange : undefined}
              disabled={!isEditable}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              label="Telefone"
              variant="outlined"
              value={companyData.telefone}
              name="telefone"
              onChange={isEditable ? handleChange : undefined}
              disabled={!isEditable}
              sx={{ flex: 1 }} // Adicionando esta linha para ocupar metade da linha
            />
            <Box flexGrow={1} /> {/* Espaço flexível */}
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              label="Nome do Responsável"
              variant="outlined"
              value={companyData.nome_responsavel}
              name="nome_responsavel"
              disabled
              className="textFieldReadOnly"
            />
            <TextField
              fullWidth
              label="CPF do Responsável"
              variant="outlined"
              value={companyData.cpf_responsavel}
              name="cpf_responsavel"
              disabled
              className="textFieldReadOnly"
            />
          </Stack>
          <TextField
            fullWidth
            label="Cargo do Responsável"
            variant="outlined"
            value={companyData.cargo_responsavel}
            name="cargo_responsavel"
            onChange={isEditable ? handleChange : undefined}
            disabled={!isEditable}
          />
        </Stack>
        <Box my={2}></Box>
        {isDataChanged && isEditable && (
          <Button
            fullWidth
            type="submit"
            variant="contained"
            size="large"
            onClick={handleSave}
          >
            Salvar Alterações
          </Button>
        )}
      </div>
      <EditCompanyNameModal 
        open={openEditCompanyNameModal} 
        handleClose={handleCloseEditCompanyNameModal} 
        companyData={companyData} 
        setCompanyData={setCompanyData} 
        token={localStorage.getItem('authToken')}
      />
      {isEditable && (
        <>
<EditCompanyLogoModal
  open={openLogoModal}
  handleClose={handleCloseLogoModal}
  setCompanyData={setCompanyData}
  logoImage={companyData.logo_image}
  razaoSocial={companyData.razao_social}
  cnpj={companyData.cnpj} // Passando o CNPJ
/>
        </>
      )}
    </div>
  );
};

export default CompanyInfoCard;
