// src/components/shared/ListCreditCards.js

import React, { useState, useEffect, useContext } from 'react';
import {
  Typography, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, Box, Button, Switch, FormControlLabel, Stack
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ProfileContext } from '../../../../context/profileContext';
import config from '../../../../config/apiConfig';
import { formatCurrency } from '../../../../utils/helpers';

import { getCreditCardByIdentifier } from '../../../../services/finance/creditCardService';
import { getCardBrandList } from '../../../../services/finance/cardBrandService';

const ListCreditCards = ({
  creditCards,
  onSelectCreditCard,
  selectedCreditCardId,
  setCreditCards,
  showRegisterButton = true,
  onRegisterNewCreditCard,
  showInactiveToggle = true,
  showManageLink = true,
  actionIcons = [],
}) => {
  const [localCreditCards, setLocalCreditCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showInactive, setShowInactive] = useState(false);
  const [showAdditionalCards, setShowAdditionalCards] = useState(true);
  const { selectedProfiles } = useContext(ProfileContext);
  const navigate = useNavigate();
  const [cardBrands, setCardBrands] = useState([]);

  useEffect(() => {
    const fetchCreditCardsAndBrands = async () => {
      setLoading(true);
      try {
        const [fetchedCreditCards, fetchedCardBrands] = await Promise.all([
          Promise.all(
            selectedProfiles.map(profile =>
              getCreditCardByIdentifier(profile.cpf || profile.cnpj)
            )
          ),
          getCardBrandList()
        ]);
        const creditCardsData = fetchedCreditCards.flat();
        setLocalCreditCards(creditCardsData);
        if (setCreditCards) setCreditCards(creditCardsData);
        setCardBrands(fetchedCardBrands);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    if (selectedProfiles.length > 0) {
      fetchCreditCardsAndBrands();
    } else {
      setLocalCreditCards([]);
      setCardBrands([]);
      setLoading(false);
    }
  }, [selectedProfiles, setCreditCards]);

  useEffect(() => {
    if (creditCards) {
      setLocalCreditCards(creditCards);
    }
  }, [creditCards]);

  const handleShowInactiveChange = () => {
    setShowInactive(!showInactive);
  };

  const handleShowAdditionalCardsChange = () => {
    setShowAdditionalCards(!showAdditionalCards);
  };

  const filteredCreditCards = localCreditCards.filter(creditCard => {
    if (!showInactive && creditCard.status === 'inativo') return false;
    if (!showAdditionalCards && creditCard.cartao_principal_id !== null) return false;
    return true;
  });

  const handleManageCreditCards = () => {
    navigate('/credit-card');
  };

  const handleSelectCreditCard = creditCardId => {
    if (onSelectCreditCard) {
      onSelectCreditCard(creditCardId);
    }
  };

  const getCardBrandLogo = (idBandeira) => {
    const brand = cardBrands.find(brand => brand.id === idBandeira);
    return brand ? `${config.serverUrl}${brand.logo_url}` : null;
  };

  return (
    <div className="dataCard">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" align="left">
          Cartões de Crédito:
        </Typography>
        <Stack direction="row" spacing={2}>
          {showInactiveToggle && (
            <FormControlLabel
              control={<Switch checked={showInactive} onChange={handleShowInactiveChange} />}
              label={<Typography variant="body2">Exibir Cartões Inativos</Typography>}
              labelPlacement="start"
            />
          )}
          <FormControlLabel
            control={<Switch checked={showAdditionalCards} onChange={handleShowAdditionalCardsChange} />}
            label={<Typography variant="body2">Exibir Cartões Adicionais</Typography>}
            labelPlacement="start"
          />
        </Stack>
      </Stack>
      <Box my={2}></Box>
      {loading ? (
        <Typography variant="body2">Carregando cartões...</Typography>
      ) : error ? (
        <Typography variant="body2">Erro ao carregar cartões: {error.message}</Typography>
      ) : selectedProfiles.length === 0 ? (
        <Typography variant="body2">
          Selecione um perfil para visualizar os cartões associados.
        </Typography>
      ) : filteredCreditCards.length === 0 ? (
        <Typography variant="body2">
          Nenhum cartão encontrado para os perfis selecionados.
        </Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Apelido</TableCell>
                <TableCell>Últimos Dígitos</TableCell>
                <TableCell>Limite de Crédito</TableCell>
                <TableCell>Fatura Atual</TableCell>
                <TableCell>Limite Disponível</TableCell>
                <TableCell>Dia de Fech.</TableCell>
                <TableCell>Dia de Venc.</TableCell>
                {actionIcons.length > 0 && <TableCell>Ações</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCreditCards.map(creditCard => (
                <TableRow
                  key={creditCard.id}
                  onClick={() => handleSelectCreditCard(creditCard.id)}
                  hover
                  selected={creditCard.id === selectedCreditCardId}
                  style={{ cursor: onSelectCreditCard ? 'pointer' : 'default' }}
                >
                  <TableCell>
                    {creditCard.cartao_principal_id === null && (
                      <img
                        src={getCardBrandLogo(creditCard.id_bandeira)}
                        alt="Logo Bandeira"
                        className="bankLogoSmall"
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <Box pl={creditCard.cartao_principal_id !== null ? 2 : 0}>
                      {creditCard.apelido}
                    </Box>
                  </TableCell>
                  <TableCell>{creditCard.ultimos_digitos}</TableCell>
                  <TableCell>{formatCurrency(creditCard.limite_credito)}</TableCell>
                  <TableCell>{formatCurrency(-creditCard.fatura_atual)}</TableCell>
                  <TableCell>{formatCurrency(creditCard.limite_disponivel)}</TableCell>
                  <TableCell>{creditCard.dia_fechamento}</TableCell>
                  <TableCell>{creditCard.dia_vencimento}</TableCell>
                  {actionIcons.length > 0 && (
                    <TableCell>
                      {actionIcons.map((icon, index) => (
                        <Button
                          key={index}
                          onClick={e => {
                            e.stopPropagation();
                            icon.onClick(creditCard);
                          }}
                        >
                          {icon.component}
                        </Button>
                      ))}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Box my={2}></Box>
      {showRegisterButton && (
        <Button
          fullWidth
          type="button"
          variant="outlined"
          size="large"
          onClick={onRegisterNewCreditCard}
          disabled={selectedProfiles.length === 0}
        >
          Cadastrar novo cartão
        </Button>
      )}
      {showManageLink && (
        <Button
          fullWidth
          type="button"
          variant="text"
          size="large"
          onClick={handleManageCreditCards}
          disabled={selectedProfiles.length === 0}
        >
          Gerenciar Cartões de Crédito
        </Button>
      )}
    </div>
  );
};

export default ListCreditCards;
