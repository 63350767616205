import axios from 'axios';
import apiConfig from '../../config/apiConfig';
import { formatCompanyProfile, formatCpfForDisplay, formatCpfForServer, formatCnpjForServer, validateCnpj, validateCpf, formatCepForServer } from '../../utils/helpers';

export const getCompanyData = async (cnpj) => {
  const token = localStorage.getItem('authToken');
  try {
    // Tratando o CNPJ para o formato do servidor
    const formattedCnpj = formatCnpjForServer(cnpj);
    const response = await axios.get(`${apiConfig.apiUrl+apiConfig.profileApiUrl}/company/${formattedCnpj}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return formatCompanyProfile(response.data);
  } catch (error) {
    console.error('Erro ao buscar dados da empresa:', error);
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);
    } else if (error.response && error.response.data) {
      throw new Error(error.response.data.message || 'Erro ao buscar dados da empresa.');
    } else {
      throw new Error('Erro ao buscar dados da empresa.');
    }
  }
};

export const updateCompany = async (cnpj, updatedFields) => {
  const token = localStorage.getItem('authToken');
  
  // Formatando o CNPJ para o formato do servidor
  const formattedCnpj = formatCnpjForServer(cnpj);

  // Validando e formatando campos específicos
  if (updatedFields.cnpj && validateCnpj(updatedFields.cnpj)) {
    updatedFields.cnpj = formatCnpjForServer(updatedFields.cnpj);
  }
  
  if (updatedFields.cpf_responsavel && validateCpf(updatedFields.cpf_responsavel)) {
    updatedFields.cpf_responsavel = formatCpfForServer(updatedFields.cpf_responsavel);
  }
  
  if (updatedFields.cep) {
    updatedFields.cep = formatCepForServer(updatedFields.cep);
  }

  try {
    await axios.put(`${apiConfig.apiUrl+apiConfig.profileApiUrl}/company/${formattedCnpj}`, updatedFields, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    return updatedFields;
  } catch (error) {
    console.error('Erro ao atualizar os dados da empresa:', error);
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);
    } else if (error.response && error.response.data) {
      throw new Error(error.response.data.message || 'Erro ao atualizar os dados da empresa.');
    } else {
      throw new Error('Erro ao atualizar os dados da empresa.');
    }
  }
};

export const createCompany = async (companyData, userCpf) => {
  const token = localStorage.getItem('authToken');
  
  // Validando e tratando CNPJ
  if (!validateCnpj(companyData.cnpj)) {
    throw new Error('CNPJ inválido');
  }
  const formattedCnpj = formatCnpjForServer(companyData.cnpj);
  
  // Validando e tratando CPF do responsável
  if (!validateCpf(userCpf)) {
    throw new Error('CPF do responsável inválido');
  }
  const formattedCpfResponsavel = formatCpfForServer(userCpf);
  
  // Tratando CEP
  const formattedCep = formatCepForServer(companyData.cep);

  const jsonData = { ...companyData, cnpj: formattedCnpj, cpf_responsavel: formattedCpfResponsavel, cep: formattedCep };

  try {
    const response = await axios.post(`${apiConfig.apiUrl+apiConfig.profileApiUrl}/company`, jsonData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    return formatCompanyProfile(response.data);
  } catch (error) {
    console.error('Erro ao registrar nova empresa:', error);

    // Tratando a resposta de erro para capturar a mensagem apropriada
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);
    } else if (error.response && error.response.data) {
      throw new Error(error.response.data.message || 'Erro ao registrar nova empresa.');
    } else {
      throw new Error('Erro ao registrar nova empresa.');
    }
  }
};

export const getTeamMembers = async (cnpj) => {
  const token = localStorage.getItem('authToken');
  try {
    // Tratando o CNPJ para o formato do servidor
    const formattedCnpj = formatCnpjForServer(cnpj);
    const response = await axios.get(`${apiConfig.apiUrl+apiConfig.profileApiUrl}/users/cnpjs/${formattedCnpj}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    // Formatando o CPF para exibição em cada membro da equipe
    const teamMembers = response.data.map(member => ({
      ...member,
      cpf_usuario: formatCpfForDisplay(member.cpf_usuario)
    }));

    return teamMembers;
  } catch (error) {
    console.error('Erro ao buscar membros da equipe:', error);
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);
    } else if (error.response && error.response.data) {
      throw new Error(error.response.data.message || 'Erro ao buscar membros da equipe.');
    } else {
      throw new Error('Erro ao buscar membros da equipe.');
    }
  }
};

export const addTeamMember = async (cnpj, cpf) => {
  const token = localStorage.getItem('authToken');
  
  // Tratando CNPJ e CPF para o formato do servidor
  const formattedCnpj = formatCnpjForServer(cnpj);
  const formattedCpf = formatCpfForServer(cpf);

  const url = `${apiConfig.apiUrl+apiConfig.profileApiUrl}/users/cnpjs/${formattedCnpj}`;
  try {
    const response = await axios.post(url, { cpf: formattedCpf }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Erro ao adicionar membro da equipe:', error);
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);
    } else if (error.response && error.response.data) {
      throw new Error(error.response.data.message || 'Erro ao adicionar membro da equipe.');
    } else {
      throw new Error('Erro ao adicionar membro da equipe.');
    }
  }
};

export const removeTeamMember = async (cnpj, cpf) => {
  const token = localStorage.getItem('authToken');

  // Tratando CNPJ e CPF para o formato do servidor
  const formattedCnpj = formatCnpjForServer(cnpj);
  const formattedCpf = formatCpfForServer(cpf);

  const url = `${apiConfig.apiUrl+apiConfig.profileApiUrl}/users/cnpjs/${formattedCnpj}`;

  try {
    const response = await axios.delete(url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      data: { cpf: formattedCpf },
    });
    return response.data;
  } catch (error) {
    console.error('Erro ao remover membro da equipe:', error);
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);
    } else if (error.response && error.response.data) {
      throw new Error(error.response.data.message || 'Erro ao remover membro da equipe.');
    } else {
      throw new Error('Erro ao remover membro da equipe.');
    }
  }
};

export const changeFile = (event, setSelectedFile) => {
  const file = event.target.files[0];
  setSelectedFile(file);
};

export const uploadCompanyLogo = async (cnpj, selectedFile) => {
  const token = localStorage.getItem('authToken');

  if (!selectedFile) {
    throw new Error('Nenhum arquivo selecionado.');
  }

  if (!cnpj) {
    throw new Error('CNPJ não fornecido.');
  }

  // Formatando o CNPJ para o formato do servidor
  const formattedCnpj = formatCnpjForServer(cnpj);

  const formData = new FormData();
  formData.append('image', selectedFile);
  formData.append('cnpj', formattedCnpj); // Usando o CNPJ formatado

  try {
    const { data } = await axios.post(`${apiConfig.apiUrl+apiConfig.profileApiUrl}/company/logo`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    return data.logo_image;
  } catch (error) {
    const errorMessage = error.response?.data?.error || 'Erro ao atualizar o logo da empresa';
    throw new Error(errorMessage);
  }
};
