import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, Typography, Box } from '@mui/material';
import { addTeamMember } from '../../../../services/profile/companyProfileService';

const AddTeamMemberModal = ({ open, handleClose, onAddSuccess, cnpj }) => {
  const [cpf, setCpf] = useState('');
  const [error, setError] = useState('');

  const handleAddTeamMember = async () => {
    setError(''); // Clear previous error

    try {
      await addTeamMember(cnpj, cpf);
      onAddSuccess(cpf);
      handleClose();
    } catch (error) {
      console.error('Error adding team member:', error);
      setError(error.message);
    }
  };

  const handleChangeCpf = (event) => {
    setCpf(event.target.value);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Adicionar Membro da Equipe</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Para adicionar um novo membro à equipe, insira o CPF do usuário que você deseja adicionar.
        </DialogContentText>
        <Box my={2}></Box>
        <TextField
          autoFocus
          margin="dense"
          id="cpf"
          label="CPF do usuário"
          type="text"
          fullWidth
          value={cpf}
          onChange={handleChangeCpf}
        />
        {error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleAddTeamMember} color="primary">
          Adicionar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTeamMemberModal;
