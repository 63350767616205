import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Typography, Stack, Box, IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import MailIcon from '@mui/icons-material/Mail';
import { register } from '../../services/auth/authService'; // Importando a função de registro
import { validateEmail, validateCpf } from '../../utils/helpers'; // Importando funções de validação
import './signup.css';
import logo from '../../img/robot.png';
import signupImage from '../../img/signup2.webp';

const Signup = () => {
  const [userData, setUserData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    cpf: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState('');
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((prev) => !prev);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let validationErrors = {};

    if (!userData.email || !validateEmail(userData.email)) {
      validationErrors.email = 'Email inválido';
    }
    if (!userData.cpf || !validateCpf(userData.cpf)) {
      validationErrors.cpf = 'CPF inválido';
    }
    if (!userData.password) {
      validationErrors.password = 'Senha obrigatória';
    }
    if (userData.password !== userData.confirmPassword) {
      validationErrors.confirmPassword = 'As senhas não coincidem';
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      await register(userData.email, userData.password, userData.cpf);
      navigate('/personal-data');
    } catch (error) {
      console.error('Erro ao registrar usuário:', error);
      setServerError(error.message); // Armazena o erro do servidor
    }
  };

  return (
    <Container maxWidth="lg" className="signupContainer">
      <Box className="header">
        <Box display="flex" alignItems="center">
          <img src={logo} alt="Logo" className="logo" />
          <Typography variant="h4" className="title">EI MONA</Typography>
        </Box>
        <Typography variant="body2" className="loginLink">
          Já registrado? <a href="/">Faça login.</a>
        </Typography>
      </Box>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="center">
        <Box flex={1} className="signupImage">
          <div className="imageContainer">
            <Typography variant="h5" gutterBottom>
              Gerencie todos os aspectos do seu negócio.
            </Typography>
            <Box my={2}></Box>
            <Typography variant="body1" gutterBottom>
              Uma solução inteligente e estruturada para gestão de recursos humanos, finanças, ERP, comercial e patrimônio, alavancada por inteligência artificial.
            </Typography>
            <Box my={2}></Box>
            <img src={signupImage} className="smallImage" alt="Signup Illustration" />
            <Box my={2}></Box>
            <Typography variant="body2" gutterBottom>
              Acesse de qualquer lugar!
            </Typography>
          </div>
        </Box>
        <Box flex={1} className="signupForm">
          <Box>
            <Typography variant="h4" component="h1" gutterBottom>
              Inicie sua jornada
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Aproveite um processo de registro simplificado. Cancele a qualquer momento.
            </Typography>
            {serverError && (
              <Typography variant="body2" color="error" gutterBottom>
                {serverError}
              </Typography>
            )}
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                margin="normal"
                label="Seu E-mail"
                variant="outlined"
                type="email"
                required
                name="email"
                value={userData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
                className="textField"
                InputLabelProps={{
                  className: "formLabel",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <MailIcon />
                    </InputAdornment>
                  ),
                  className: "formInput",
                }}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Seu CPF"
                variant="outlined"
                type="text"
                required
                name="cpf"
                value={userData.cpf}
                onChange={handleChange}
                error={!!errors.cpf}
                helperText={errors.cpf}
                className="textField"
                InputLabelProps={{
                  className: "formLabel",
                }}
                InputProps={{
                  className: "formInput",
                }}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Senha"
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                required
                name="password"
                value={userData.password}
                onChange={handleChange}
                error={!!errors.password}
                helperText={errors.password}
                className="textField"
                InputLabelProps={{
                  className: "formLabel",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Confirme a Senha"
                variant="outlined"
                type={showConfirmPassword ? 'text' : 'password'}
                required
                name="confirmPassword"
                value={userData.confirmPassword}
                onChange={handleChange}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
                className="textField"
                InputLabelProps={{
                  className: "formLabel",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Box my={2}></Box> {/* Adiciona um espaçamento */}
              <Button
                fullWidth
                type="submit"
                variant="contained"
                className="buttonOrange"
                size="large"
              >
                Registrar
              </Button>
            </form>
          </Box>
        </Box>
      </Stack>
    </Container>
  );
};

export default Signup;
