// src/modules/sales/quotations/cards/printQuotationModal.js

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { formatCurrency, formatCpfForDisplay, formatCnpjForDisplay } from '../../../../utils/helpers';
import { getCompanyData } from '../../../../services/profile/companyProfileService';
import { getProfileDataByCpf } from '../../../../services/profile/userProfileService';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import config from '../../../../config/apiConfig';

const PrintQuotationModal = ({ open, handleClose, quotation }) => {
  const [titularData, setTitularData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTitularData = async () => {
      try {
        if (quotation.titular_cpf) {
          const data = await getProfileDataByCpf(quotation.titular_cpf);
          setTitularData(data);
        } else if (quotation.titular_cnpj) {
          const data = await getCompanyData(quotation.titular_cnpj);
          setTitularData(data);
        }
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar dados do titular:', error);
        setLoading(false);
      }
    };

    if (open && quotation) {
      fetchTitularData();
    }
  }, [open, quotation]);

  const handleDownloadPDF = async () => {
    const element = document.getElementById('quotation-content');
    const canvas = await html2canvas(element, {
      scale: 2,
      logging: false,
      useCORS: true,
      backgroundColor: '#ffffff'
    });
    
    const imgData = canvas.toDataURL('image/jpeg', 1.0);
    
    // Dimensões do PDF (A4)
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
      compress: true
    });
    
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    
    // Calcular dimensões mantendo a proporção
    const ratio = canvas.width / canvas.height;
    let imgWidth = pageWidth - 20; // margem de 10mm em cada lado
    let imgHeight = imgWidth / ratio;
    
    // Se a altura for maior que a página, ajustar proporcionalmente
    if (imgHeight > pageHeight - 20) {
      imgHeight = pageHeight - 20;
      imgWidth = imgHeight * ratio;
    }
    
    // Centralizar na página
    const x = (pageWidth - imgWidth) / 2;
    const y = (pageHeight - imgHeight) / 2;
    
    pdf.addImage(imgData, 'JPEG', x, y, imgWidth, imgHeight, undefined, 'FAST');
    pdf.save(`orcamento_${quotation.numero_os}.pdf`);
  };

  if (loading || !quotation || !titularData) {
    return null;
  }

  const calculateTotal = (items) => {
    return items?.reduce((total, item) => 
      total + (item.quantidade * item.preco_unitario), 0) || 0;
  };

  const servicosTotal = calculateTotal(quotation.servicos);
  const produtosTotal = calculateTotal(quotation.produtos);
  const total = servicosTotal + produtosTotal - (quotation.desconto || 0);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h6" sx={{ flex: 1, textAlign: 'center' }}>
            Visualização do Orçamento
          </Typography>
          <IconButton 
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box 
          id="quotation-content" 
          sx={{ 
            p: 3, 
            backgroundColor: '#ffffff',
            color: '#000000',
            minHeight: '297mm', // Altura A4
            position: 'relative',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {/* Cabeçalho */}
          <Box 
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              mb: 3,
              width: '100%'
            }}
          >
            {/* Informações da empresa/pessoa */}
            <Box sx={{ flex: 1 }}>
              <Typography variant="h5" sx={{ color: '#000000', mb: 1 }}>
                {titularData.razao_social || `${titularData.nome} ${titularData.sobrenome}`}
              </Typography>
              <Typography variant="body2" sx={{ color: '#000000' }}>
                {quotation.titular_cnpj ? 
                  `CNPJ: ${formatCnpjForDisplay(quotation.titular_cnpj)}` : 
                  `CPF: ${formatCpfForDisplay(quotation.titular_cpf)}`}
              </Typography>
              <Typography variant="body2" sx={{ color: '#000000' }}>
                Telefone: {titularData.telefone}
              </Typography>
            </Box>
            
            {/* Logo */}
            {titularData.logo_image && (
              <Box 
                sx={{
                  width: '100px',
                  height: '100px',
                  borderRadius: '50%',
                  overflow: 'hidden',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  bgcolor: 'background.paper',
                  ml: 2
                }}
              >
                <img 
                  src={`${config.serverUrl}${titularData.logo_image}`} 
                  alt="Logo" 
                  style={{ 
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                  }}
                />
              </Box>
            )}
          </Box>

          <Divider sx={{ 
            my: 2, 
            backgroundColor: '#000000',
            height: '2px'
          }} />

          {/* Conteúdo Principal */}
          <Box sx={{ flex: 1 }}>
            {/* Informações do Orçamento */}
            <Box mb={3} sx={{ color: '#000000' }}>
              <Typography variant="h6" gutterBottom sx={{ color: '#000000' }}>
                Orçamento Nº {quotation.numero_os}
              </Typography>
              <Typography sx={{ color: '#000000' }}>
                Cliente: {quotation.cliente}
              </Typography>
              <Typography sx={{ color: '#000000' }}>
                Referência: {quotation.referencia}
              </Typography>
              <Typography sx={{ color: '#000000' }}>
                Data: {new Date(quotation.data_criacao).toLocaleDateString()}
              </Typography>
            </Box>

            {/* Serviços */}
            {quotation.servicos?.length > 0 && (
              <Box mb={3}>
                <Typography variant="h6" gutterBottom sx={{ color: '#000000' }}>
                  Serviços
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: '#000000', borderColor: '#000000' }}>
                        Descrição
                      </TableCell>
                      <TableCell align="right" sx={{ color: '#000000', borderColor: '#000000' }}>
                        Qtde
                      </TableCell>
                      <TableCell align="right" sx={{ color: '#000000', borderColor: '#000000' }}>
                        Valor Unit.
                      </TableCell>
                      <TableCell align="right" sx={{ color: '#000000', borderColor: '#000000' }}>
                        Total
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {quotation.servicos.map((servico, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ color: '#000000', borderColor: '#000000' }}>
                          {servico.descricao}
                        </TableCell>
                        <TableCell align="right" sx={{ color: '#000000', borderColor: '#000000' }}>
                          {servico.quantidade}
                        </TableCell>
                        <TableCell align="right" sx={{ color: '#000000', borderColor: '#000000' }}>
                          {formatCurrency(servico.preco_unitario)}
                        </TableCell>
                        <TableCell align="right" sx={{ color: '#000000', borderColor: '#000000' }}>
                          {formatCurrency(servico.quantidade * servico.preco_unitario)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            )}

            {/* Produtos */}
            {quotation.produtos?.length > 0 && (
              <Box mb={3}>
                <Typography variant="h6" gutterBottom sx={{ color: '#000000' }}>
                  Produtos
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: '#000000', borderColor: '#000000' }}>
                        Descrição
                      </TableCell>
                      <TableCell align="right" sx={{ color: '#000000', borderColor: '#000000' }}>
                        Qtde
                      </TableCell>
                      <TableCell align="right" sx={{ color: '#000000', borderColor: '#000000' }}>
                        Valor Unit.
                      </TableCell>
                      <TableCell align="right" sx={{ color: '#000000', borderColor: '#000000' }}>
                        Total
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {quotation.produtos.map((produto, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ color: '#000000', borderColor: '#000000' }}>
                          {produto.descricao}
                        </TableCell>
                        <TableCell align="right" sx={{ color: '#000000', borderColor: '#000000' }}>
                          {produto.quantidade}
                        </TableCell>
                        <TableCell align="right" sx={{ color: '#000000', borderColor: '#000000' }}>
                          {formatCurrency(produto.preco_unitario)}
                        </TableCell>
                        <TableCell align="right" sx={{ color: '#000000', borderColor: '#000000' }}>
                          {formatCurrency(produto.quantidade * produto.preco_unitario)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            )}

            {/* Totais */}
            <Box textAlign="right" mb={3} sx={{ color: '#000000' }}>
              <Typography sx={{ color: '#000000' }}>
                Subtotal Serviços: {formatCurrency(servicosTotal)}
              </Typography>
              <Typography sx={{ color: '#000000' }}>
                Subtotal Produtos: {formatCurrency(produtosTotal)}
              </Typography>
              <Typography sx={{ color: '#000000' }}>
                Desconto: {formatCurrency(quotation.desconto || 0)}
              </Typography>
              <Typography variant="h6" sx={{ color: '#000000' }}>
                Total: {formatCurrency(total)}
              </Typography>
            </Box>

            {/* Observações */}
            {quotation.observacoes && (
              <Box mb={3}>
                <Typography variant="h6" gutterBottom sx={{ color: '#000000' }}>
                  Observações
                </Typography>
                <Typography sx={{ color: '#000000' }}>
                  {quotation.observacoes}
                </Typography>
              </Box>
            )}
          </Box>

          {/* Rodapé */}
          <Box 
            sx={{ 
              mt: 'auto',
              pt: 4,
              borderTop: '2px solid #000000',
              textAlign: 'center',
              color: '#000000'
            }}
          >
            <Typography variant="body2" sx={{ color: '#000000' }}>
              {titularData.logradouro}, {titularData.numero}
              {titularData.complemento ? ` - ${titularData.complemento}` : ''}
            </Typography>
            <Typography variant="body2" sx={{ color: '#000000' }}>
              {titularData.bairro} - {titularData.cidade}/{titularData.estado}
            </Typography>
            <Typography variant="body2" sx={{ color: '#000000' }}>
              CEP: {titularData.cep}
            </Typography>
          </Box>
        </Box>

        <Box mt={2} display="flex" justifyContent="center">
          <Button variant="contained" color="primary" onClick={handleDownloadPDF}>
            Baixar PDF
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PrintQuotationModal;