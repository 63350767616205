import axios from 'axios';
import config from '../../config/apiConfig';
import {
  formatCpfForServer,
  formatCpfForDisplay,
  formatCnpjForServer,
  formatCnpjForDisplay,
  validateCpf,
  validateCnpj
} from '../../utils/helpers';

export const createFinancialOperation = async (operationData) => {
  const token = localStorage.getItem('authToken');

  try {
    let cleanIdentifier;
    if (operationData.titular_cpf) {
      cleanIdentifier = operationData.titular_cpf.replace(/\D/g, '');
    } else if (operationData.titular_cnpj) {
      cleanIdentifier = operationData.titular_cnpj.replace(/\D/g, '');
    } else {
      throw new Error('É necessário fornecer um CPF ou CNPJ válido.');
    }

    let formattedCpf = null;
    let formattedCnpj = null;

    if (cleanIdentifier.length === 11) {
      if (!validateCpf(cleanIdentifier)) {
        throw new Error('CPF inválido');
      }
      formattedCpf = formatCpfForServer(cleanIdentifier);
    } else if (cleanIdentifier.length === 14) {
      if (!validateCnpj(cleanIdentifier)) {
        throw new Error('CNPJ inválido');
      }
      formattedCnpj = formatCnpjForServer(cleanIdentifier);
    } else {
      throw new Error('Identificador inválido. Forneça um CPF ou CNPJ válido.');
    }

    const requiredFields = ['nome', 'tipo_operacao', 'nivel'];
    for (const field of requiredFields) {
      if (!operationData[field]) {
        throw new Error(`Campo obrigatório não preenchido: ${field}`);
      }
    }

    if (operationData.nivel === 1 && operationData.operacao_pai_id) {
      throw new Error('Operação de nível 1 não pode ter uma operação pai.');
    } else if (operationData.nivel > 1 && !operationData.operacao_pai_id) {
      throw new Error('Operações de nível superior a 1 devem ter uma operação pai.');
    }

    const jsonData = {
      ...operationData,
      titular_cpf: formattedCpf,
      titular_cnpj: formattedCnpj,
    };

    const response = await axios.post(`${config.apiUrl+config.financeApiUrl}/financial-operations`, jsonData, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    const operation = response.data;
    if (operation.titular_cpf) {
      operation.titular_cpf = formatCpfForDisplay(operation.titular_cpf);
    }
    if (operation.titular_cnpj) {
      operation.titular_cnpj = formatCnpjForDisplay(operation.titular_cnpj);
    }
    return operation;
  } catch (error) {
    handleServiceError(error, 'Erro ao criar operação financeira.');
  }
};

export const updateFinancialOperation = async (operationID, updatedOperationData) => {
  const token = localStorage.getItem('authToken');

  if (updatedOperationData.titular_cpf && validateCpf(updatedOperationData.titular_cpf)) {
    updatedOperationData.titular_cpf = formatCpfForServer(updatedOperationData.titular_cpf);
  }
  if (updatedOperationData.titular_cnpj && validateCnpj(updatedOperationData.titular_cnpj)) {
    updatedOperationData.titular_cnpj = formatCnpjForServer(updatedOperationData.titular_cnpj);
  }

  if (updatedOperationData.nivel) {
    if (updatedOperationData.nivel === 1 && updatedOperationData.operacao_pai_id) {
      throw new Error('Operação de nível 1 não pode ter uma operação pai.');
    } else if (updatedOperationData.nivel > 1 && !updatedOperationData.operacao_pai_id) {
      throw new Error('Operações de nível superior a 1 devem ter uma operação pai.');
    }
  }

  try {
    console.log('Atualizando operação:', { operationID, updatedOperationData });
    const response = await axios.put(`${config.apiUrl+config.financeApiUrl}/financial-operations/${operationID}`, updatedOperationData, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    const operation = response.data;
    if (operation.titular_cpf) {
      operation.titular_cpf = formatCpfForDisplay(operation.titular_cpf);
    }
    if (operation.titular_cnpj) {
      operation.titular_cnpj = formatCnpjForDisplay(operation.titular_cnpj);
    }
    return operation;
  } catch (error) {
    handleServiceError(error, 'Erro ao atualizar operação financeira.');
  }
};

export const getFinancialOperationByID = async (operationID) => {
  const token = localStorage.getItem('authToken');

  try {
    const response = await axios.get(`${config.apiUrl+config.financeApiUrl}/financial-operations/id/${operationID}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    const operation = response.data;
    if (operation.titular_cpf) {
      operation.titular_cpf = formatCpfForDisplay(operation.titular_cpf);
    }
    if (operation.titular_cnpj) {
      operation.titular_cnpj = formatCnpjForDisplay(operation.titular_cnpj);
    }
    return operation;
  } catch (error) {
    handleServiceError(error, 'Erro ao buscar operação financeira.');
  }
};

export const getFinancialOperationsByIdentifier = async (identifier) => {
  const token = localStorage.getItem('authToken');

  let formattedIdentifier;
  if (validateCpf(identifier)) {
    formattedIdentifier = formatCpfForServer(identifier);
  } else if (validateCnpj(identifier)) {
    formattedIdentifier = formatCnpjForServer(identifier);
  } else {
    throw new Error('Identificador inválido. Forneça um CPF ou CNPJ válido.');
  }

  try {
    const response = await axios.get(`${config.apiUrl+config.financeApiUrl}/financial-operations/${formattedIdentifier}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    const operations = response.data.map(operation => {
      if (operation.titular_cpf) {
        operation.titular_cpf = formatCpfForDisplay(operation.titular_cpf);
      }
      if (operation.titular_cnpj) {
        operation.titular_cnpj = formatCnpjForDisplay(operation.titular_cnpj);
      }
      return operation;
    });

    return operations;
  } catch (error) {
    handleServiceError(error, 'Erro ao buscar operações financeiras por CPF ou CNPJ.');
  }
};

const handleServiceError = (error, defaultMessage) => {
  console.error(defaultMessage, error);
  if (error.response && error.response.data && error.response.data.error) {
    throw new Error(error.response.data.error);
  } else if (error.response && error.response.data) {
    throw new Error(error.response.data.message || defaultMessage);
  } else {
    throw new Error(defaultMessage);
  }
};
