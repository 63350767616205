const config = {
  apiUrl: 'https://api.eimona.com.br/api',
  serverUrl: 'https://api.eimona.com.br',

  //apiUrl: 'http://26.249.239.75:3001/api',
  //serverUrl: 'http://26.249.239.75:3001',

  //apiUrl: 'http://localhost:3001/api',
  //serverUrl: 'http://localhost:3001',


  
  loginRedirectUrl: '/dashboard', // URL para redirecionamento após login bem-sucedido
  defaultRedirectUrl: '/', // URL padrão para redirecionamento

  accountingApiUrl: '/accounting',
  assetsApiUrl: '/assets',
  authApiUrl: '/auth',
  biApiUrl: '/bi',
  crmApiUrl: '/crm',
  ecommerceApiUrl: '/ecommerce',
  financeApiUrl: '/finance',
  hrApiUrl: '/hr',
  inventoryApiUrl: '/inventory',
  logisticsApiUrl: '/logistics',
  productionApiUrl: '/production',
  profileApiUrl: '/profile',
  purchasingApiUrl: '/purchasing',
  salesApiUrl: '/sales',
};

export default config;
