import axios from 'axios';
import config from '../../config/apiConfig';
import {
  formatCpfForServer,
  formatCpfForDisplay,
  formatCnpjForServer,
  formatCnpjForDisplay,
  validateCpf,
  validateCnpj
} from '../../utils/helpers';

export const createCreditCard = async (creditCardData) => {
  const token = localStorage.getItem('authToken');

  // Limpa o campo titular para remover caracteres não numéricos
  const cleanIdentifier = creditCardData.titular.replace(/\D/g, '');

  let formattedCpf = null;
  let formattedCnpj = null;

  // Determina se o identificador é CPF ou CNPJ com base no número de dígitos
  if (cleanIdentifier.length === 11) {
    if (!validateCpf(cleanIdentifier)) {
      throw new Error('CPF inválido');
    }
    formattedCpf = formatCpfForServer(cleanIdentifier);
  } else if (cleanIdentifier.length === 14) {
    if (!validateCnpj(cleanIdentifier)) {
      throw new Error('CNPJ inválido');
    }
    formattedCnpj = formatCnpjForServer(cleanIdentifier);
  } else {
    throw new Error('Identificador inválido. Forneça um CPF ou CNPJ válido.');
  }

  // Verificação dos campos obrigatórios
  const requiredFields = ['id_bandeira', 'funcoes_cartao', 'ultimos_digitos', 'cartao_virtual', 'data_expiracao'];
  
  // Verifica se limite_credito é obrigatório apenas se cartao_principal_id não estiver presente
  if (!creditCardData.cartao_principal_id) {
    requiredFields.push('limite_credito');
  }

  for (const field of requiredFields) {
    if (creditCardData[field] === undefined || creditCardData[field] === null || creditCardData[field] === '') {
      throw new Error(`Campo obrigatório não preenchido: ${field}`);
    }
  }

  // Validação e formatação do campo data_expiracao
  const dataExpiracaoPattern = /^(\d{2})\/(\d{2})$/;
  if (!dataExpiracaoPattern.test(creditCardData.data_expiracao)) {
    if (creditCardData.data_expiracao.length === 4) {
      const mes = creditCardData.data_expiracao.substring(0, 2);
      const ano = creditCardData.data_expiracao.substring(2);
      creditCardData.data_expiracao = `${mes}/${ano}`;
    } else {
      throw new Error('Data de Expiração inválida. O formato deve ser MM/AA.');
    }
  }

  const jsonData = {
    ...creditCardData,
    titular_cpf: formattedCpf,
    titular_cnpj: formattedCnpj,
  };

  try {
    const response = await axios.post(`${config.apiUrl+config.financeApiUrl}/creditcards`, jsonData, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    // Formatação dos dados para display
    const creditCard = response.data;
    if (creditCard.titular_cpf) {
      creditCard.titular_cpf = formatCpfForDisplay(creditCard.titular_cpf);
    }
    if (creditCard.titular_cnpj) {
      creditCard.titular_cnpj = formatCnpjForDisplay(creditCard.titular_cnpj);
    }
    return creditCard;
  } catch (error) {
    handleServiceError(error, 'Erro ao criar cartão de crédito.');
  }
};


export const updateCreditCard = async (creditCardID, updatedCreditCardData) => {
  const token = localStorage.getItem('authToken');

  // Verificação e formatação de CPF/CNPJ, se fornecido
  if (updatedCreditCardData.titular_cpf && validateCpf(updatedCreditCardData.titular_cpf)) {
    updatedCreditCardData.titular_cpf = formatCpfForServer(updatedCreditCardData.titular_cpf);
  }
  if (updatedCreditCardData.titular_cnpj && validateCnpj(updatedCreditCardData.titular_cnpj)) {
    updatedCreditCardData.titular_cnpj = formatCnpjForServer(updatedCreditCardData.titular_cnpj);
  }

  // Verificação de campos obrigatórios apenas se forem enviados
  const requiredFields = ['limite_credito', 'bandeira', 'tipo_cartao', 'funcoes_cartao', 'ultimos_digitos', 'cartao_virtual', 'data_expiracao'];
  for (const field of requiredFields) {
    if (updatedCreditCardData.hasOwnProperty(field) && !updatedCreditCardData[field]) {
      throw new Error(`Campo obrigatório não preenchido: ${field}`);
    }
  }

  // Validação e formatação do campo data_expiracao
  const dataExpiracaoPattern = /^(\d{2})\/(\d{2})$/;
  if (updatedCreditCardData.data_expiracao && !dataExpiracaoPattern.test(updatedCreditCardData.data_expiracao)) {
    if (updatedCreditCardData.data_expiracao.length === 4) {
      const mes = updatedCreditCardData.data_expiracao.substring(0, 2);
      const ano = updatedCreditCardData.data_expiracao.substring(2);
      updatedCreditCardData.data_expiracao = `${mes}/${ano}`;
    } else {
      throw new Error('Data de Expiração inválida. O formato deve ser MM/AA.');
    }
  }

  try {
    const response = await axios.put(`${config.apiUrl+config.financeApiUrl}/creditcards/${creditCardID}`, updatedCreditCardData, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    // Formatação dos dados para display
    const creditCard = response.data;
    if (creditCard.titular_cpf) {
      creditCard.titular_cpf = formatCpfForDisplay(creditCard.titular_cpf);
    }
    if (creditCard.titular_cnpj) {
      creditCard.titular_cnpj = formatCnpjForDisplay(creditCard.titular_cnpj);
    }
    return creditCard;
  } catch (error) {
    handleServiceError(error, 'Erro ao atualizar cartão de crédito.');
  }
};

export const getCreditCardByID = async (creditCardID) => {
  const token = localStorage.getItem('authToken');

  try {
    const response = await axios.get(`${config.apiUrl+config.financeApiUrl}/creditcards/id/${creditCardID}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    // Formatação dos dados para display
    const creditCard = response.data;
    if (creditCard.titular_cpf) {
      creditCard.titular_cpf = formatCpfForDisplay(creditCard.titular_cpf);
    }
    if (creditCard.titular_cnpj) {
      creditCard.titular_cnpj = formatCnpjForDisplay(creditCard.titular_cnpj);
    }
    return creditCard;
  } catch (error) {
    handleServiceError(error, 'Erro ao buscar cartão de crédito.');
  }
};

export const getCreditCardByIdentifier = async (identifier) => {
  const token = localStorage.getItem('authToken');

  // Determina se o identificador é CPF ou CNPJ e formata para o servidor
  let formattedIdentifier;
  if (validateCpf(identifier)) {
    formattedIdentifier = formatCpfForServer(identifier);
  } else if (validateCnpj(identifier)) {
    formattedIdentifier = formatCnpjForServer(identifier);
  } else {
    throw new Error('Identificador inválido. Forneça um CPF ou CNPJ válido.');
  }

  try {
    const response = await axios.get(`${config.apiUrl+config.financeApiUrl}/creditcards/${formattedIdentifier}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    // Formatação dos dados para display
    const creditCards = response.data.map(creditCard => {
      if (creditCard.titular_cpf) {
        creditCard.titular_cpf = formatCpfForDisplay(creditCard.titular_cpf);
      }
      if (creditCard.titular_cnpj) {
        creditCard.titular_cnpj = formatCnpjForDisplay(creditCard.titular_cnpj);
      }
      return creditCard;
    });

    return creditCards;
  } catch (error) {
    handleServiceError(error, 'Erro ao buscar cartão de crédito por CPF ou CNPJ.');
  }
};

// Função auxiliar para tratamento de erros
const handleServiceError = (error, defaultMessage) => {
  console.error(defaultMessage, error);
  if (error.response && error.response.data && error.response.data.error) {
    throw new Error(error.response.data.error);
  } else if (error.response && error.response.data) {
    throw new Error(error.response.data.message || defaultMessage);
  } else {
    throw new Error(defaultMessage);
  }
};
