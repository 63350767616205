import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button, IconButton, Avatar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getInitials } from '../../../../utils/helpers';
import config from '../../../../config/apiConfig';
import { changeFile, uploadUserImage } from '../../../../services/profile/userProfileService';


const ProfileImageModal = ({ open, handleClose, setProfileData, profileImage, nome, sobrenome, refreshPersonalInfo, refreshPersonalProfiles }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  // Atualiza o preview sempre que a imagem do perfil for alterada
  useEffect(() => {
    setPreview(profileImage ? `${config.serverUrl}${profileImage}` : null);
  }, [profileImage]);

  const handleFileSelect = (event) => {
    changeFile(event, setSelectedFile);
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    try {
      const updatedProfileImage = await uploadUserImage(selectedFile);
      setProfileData((prevData) => ({
        ...prevData,
        profile_image: updatedProfileImage,
      }));

      // Atualiza os cards
      if (typeof refreshPersonalInfo === 'function') refreshPersonalInfo();
      if (typeof refreshPersonalProfiles === 'function') refreshPersonalProfiles();

      handleClose(); // Fechar modal após sucesso
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="modalBox" sx={{ textAlign: 'center', p: 3, position: 'relative' }}>
        <IconButton aria-label="close" onClick={handleClose} className="modalCloseButton" sx={{ position: 'absolute', top: 8, right: 8 }}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="h2">
          Atualizar Imagem de Perfil
        </Typography>
        <Box mt={2} mb={2}>
          {preview ? (
            <Avatar
              src={preview}
              alt="Profile"
              sx={{ width: 200, height: 200, margin: 'auto', borderRadius: '50%' }}
            />
          ) : (
            <Avatar sx={{ width: 200, height: 200, margin: 'auto', borderRadius: '50%', bgcolor: 'primary.main', fontSize: 80 }}>
              {getInitials(nome, sobrenome)}
            </Avatar>
          )}
        </Box>
        <Box mt={2}>
          <input type="file" onChange={handleFileSelect} />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            sx={{ mt: 2 }}
          >
            Salvar
          </Button>
        </Box>
        {errorMessage && (
          <Typography variant="body2" color="error" sx={{ mt: 2 }}>
            {errorMessage}
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export default ProfileImageModal;
