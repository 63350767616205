import React, { useState } from 'react';
import { TextField, Typography, Button, Grid, MenuItem, Box, Switch, FormControlLabel } from '@mui/material';

import { funcoesCartao, statusCartao } from '../../../../config/constants';
import { BankSelector, TitularSelector, CreditCardSelector, CardBrandSelector } from '../../../../utils/formUtils';

import { createCreditCard } from '../../../../services/finance/creditCardService';


const RegisterCreditCard = ({ onCreditCardRegistered }) => {
  const [creditCardData, setCreditCardData] = useState({
    apelido: '',
    ultimos_digitos: '',
    id_bandeira: '',  // Substituindo 'bandeira' por 'id_bandeira'
    funcoes_cartao: '',  // Campo atualizado com o nome correto
    cartao_virtual: false,
    cartao_adicional: false,
    cartao_principal_id: '', // Alterado de cartao_principal para cartao_principal_id
    dia_fechamento: '',
    dia_vencimento: '',
    data_expiracao: '',
    limite_credito: '',
    usuario_cartao: '',
    status: '',
    descricao: '',
    id_banco: '',
    titular: ''
  });

  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCreditCardData({ ...creditCardData, [name]: value });
  };

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    if (name === 'cartao_adicional') {
      setCreditCardData({ 
        ...creditCardData, 
        [name]: checked,
        cartao_principal_id: checked ? creditCardData.cartao_principal_id : '', // Atualizado aqui também
        dia_fechamento: checked ? '' : creditCardData.dia_fechamento,
        dia_vencimento: checked ? '' : creditCardData.dia_vencimento,
        limite_credito: checked ? '' : creditCardData.limite_credito
      });
    } else {
      setCreditCardData({ ...creditCardData, [name]: checked });
    }
  };

  const handleRegister = async () => {
    try {
      const newCreditCard = await createCreditCard(creditCardData);
      onCreditCardRegistered(newCreditCard); // Passa o novo cartão criado
      setErrorMessage('');
    } catch (error) {
      console.error("Erro ao registrar novo cartão:", error);
      setErrorMessage(`Erro ao registrar novo cartão: ${error.message}`);
    }
  };

  return (
    <div className="creditCardInfoCard">
      <Typography variant="h5">Cadastrar Novo Cartão</Typography>

      {errorMessage && (
        <Typography variant="body2" color="error" gutterBottom>
          {errorMessage}
        </Typography>
      )}
      <Box my={2}></Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitularSelector
            value={creditCardData.titular}
            onChange={(identifier) => {
              setCreditCardData({
                ...creditCardData,
                titular: identifier
              });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <BankSelector
            value={creditCardData.id_banco}
            onChange={(e) => handleChange({ target: { name: 'id_banco', value: e.target.value } })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Switch
                checked={creditCardData.cartao_virtual}
                onChange={handleSwitchChange}
                name="cartao_virtual"
                color="primary"
              />
            }
            label="Cartão Virtual"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Switch
                checked={creditCardData.cartao_adicional}
                onChange={handleSwitchChange}
                name="cartao_adicional"
                color="primary"
              />
            }
            label="Cartão Adicional"
          />
        </Grid>
        {creditCardData.cartao_adicional ? (
          <Grid item xs={12} sm={6}>
            <CreditCardSelector
              value={creditCardData.cartao_principal_id} // Atualizado aqui
              onChange={(value) => setCreditCardData({ ...creditCardData, cartao_principal_id: value })} // E aqui
              titular={creditCardData.titular}
              label="Cartão Principal"
            />
          </Grid>
        ) : (
          <Grid item xs={12} sm={6}></Grid>
        )}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Apelido"
            variant="outlined"
            value={creditCardData.apelido}
            name="apelido"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Últimos Dígitos"
            variant="outlined"
            value={creditCardData.ultimos_digitos}
            name="ultimos_digitos"
            onChange={handleChange}
            inputProps={{ maxLength: 4 }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardBrandSelector
            value={creditCardData.id_bandeira}
            onChange={(e) => handleChange({ target: { name: 'id_bandeira', value: e.target.value } })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            select
            fullWidth
            label="Função"
            variant="outlined"
            name="funcoes_cartao"  // Nome do campo corrigido
            value={creditCardData.funcoes_cartao}
            onChange={handleChange}
          >
            {funcoesCartao.map((funcao) => (
              <MenuItem key={funcao.valor} value={funcao.valor}>
                {funcao.nome}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {!creditCardData.cartao_adicional && (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Dia de Fechamento"
                variant="outlined"
                value={creditCardData.dia_fechamento}
                name="dia_fechamento"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Dia de Vencimento"
                variant="outlined"
                value={creditCardData.dia_vencimento}
                name="dia_vencimento"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Limite de Crédito"
                variant="outlined"
                value={creditCardData.limite_credito}
                name="limite_credito"
                onChange={handleChange}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Data de Expiração"
            variant="outlined"
            value={creditCardData.data_expiracao}
            name="data_expiracao"
            onChange={handleChange}
            placeholder="MM/AA"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Usuário do Cartão"
            variant="outlined"
            value={creditCardData.usuario_cartao}
            name="usuario_cartao"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            select
            fullWidth
            label="Status"
            variant="outlined"
            name="status"
            value={creditCardData.status}
            onChange={handleChange}
          >
            {statusCartao.map((status) => (
              <MenuItem key={status.valor} value={status.valor}>
                {status.nome}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            label="Descrição"
            variant="outlined"
            value={creditCardData.descricao}
            name="descricao"
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Box my={2}></Box>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleRegister}
      >
        Cadastrar Cartão
      </Button>
    </div>
  );
};

export default RegisterCreditCard;
