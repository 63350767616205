import React, { useState, useEffect } from 'react';
import { Typography, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import ShareProfileModal from './shareProfile';
import { getInitials } from '../../../../utils/helpers';
import config from '../../../../config/apiConfig';
import { getUsersWithAccess, getProfileDataByCpf, removeUserAccess } from '../../../../services/profile/userProfileService';


const ProfileAccessCard = ({ userCpf, token, refreshKey, refreshProfileAccess }) => {
  const [profileAccess, setProfileAccess] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedProfileToDelete, setSelectedProfileToDelete] = useState(null);
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');  // Novo estado para a mensagem de erro
  const [successMessage, setSuccessMessage] = useState('');  // Novo estado para a mensagem de sucesso

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!userCpf) return;

        const cpfsWithAccess = await getUsersWithAccess(userCpf);
        const fullProfileAccess = await Promise.all(
          cpfsWithAccess.map(async ({ cpf_usuario }) => {
            const profile = await getProfileDataByCpf(cpf_usuario, token);
            return {
              ...profile,
              nome_completo: `${profile.nome} ${profile.sobrenome}`,
              profile_image: profile.profile_image,
              cpfBruto: cpf_usuario,
              cpfFormatado: profile.cpf,
            };
          })
        );
        setProfileAccess(fullProfileAccess);
      } catch (error) {
        console.error('Erro ao buscar dados de acesso ao perfil:', error);
        setErrorMessage('Erro ao carregar os acessos ao perfil.');
      }
    };

    fetchData();
  }, [refreshKey, token, userCpf]);

  const handleOpenDeleteDialog = (profile) => {
    setSelectedProfileToDelete(profile);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedProfileToDelete(null);
  };

  const handleRemoveAccess = async () => {
    if (!selectedProfileToDelete) return;

    try {
      await removeUserAccess(selectedProfileToDelete.cpfBruto, profileAccess, setProfileAccess, handleCloseDeleteDialog);
      setProfileAccess(profileAccess.filter(profile => profile.cpfBruto !== selectedProfileToDelete.cpfBruto));
      setSuccessMessage('Acesso removido com sucesso!');
      setErrorMessage('');
      handleCloseDeleteDialog();
      refreshProfileAccess();
    } catch (error) {
      console.error('Erro ao remover acesso ao perfil:', error);
      setErrorMessage('Erro ao remover acesso ao perfil.');
      setSuccessMessage('');
    }
  };

  const handleOpenShareDialog = () => {
    setOpenShareDialog(true);
  };

  const handleCloseShareDialog = () => {
    setOpenShareDialog(false);
  };

  const handleShareSuccess = async (cpf) => {
    try {
      const newProfile = await getProfileDataByCpf(cpf, token);
      newProfile.nome_completo = `${newProfile.nome} ${newProfile.sobrenome}`;
      setProfileAccess([...profileAccess, {
        cpfBruto: cpf,
        cpfFormatado: newProfile.cpf,
        nome: newProfile.nome,
        sobrenome: newProfile.sobrenome,
        nome_completo: newProfile.nome_completo,
        profile_image: newProfile.profile_image,
      }]);
      setSuccessMessage('Perfil compartilhado com sucesso!');
      setErrorMessage('');
      handleCloseShareDialog();
    } catch (error) {
      console.error('Erro ao buscar dados do perfil compartilhado:', error);
      setErrorMessage('Erro ao compartilhar o perfil.');
      setSuccessMessage('');
    }
  };

  return (
    <div className="profileCard">
      <Typography variant="h5">Quem Acessa o Seu Perfil:</Typography>

      {/* Espaço para exibir a mensagem de erro ou sucesso */}
      {errorMessage && (
        <Typography variant="body2" color="error" gutterBottom>
          {errorMessage}
        </Typography>
      )}
      {successMessage && (
        <Typography variant="body2" color="primary" gutterBottom>
          {successMessage}
        </Typography>
      )}

      <Box my={2}></Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Nome Completo</TableCell>
              <TableCell>CPF</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {profileAccess.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  Você não está compartilhando seu perfil com nenhum usuário.
                </TableCell>
              </TableRow>
            ) : (
              profileAccess.map((profile, index) => (
                <TableRow key={`${profile.cpfBruto}-${index}`} hover>
                  <TableCell>{profile.profile_image ? <img src={`${config.serverUrl}${profile.profile_image}`} alt="Pessoa" className="profileAvatarSmall" /> : <div className="profileAvatarSmall">{getInitials(profile.nome, profile.sobrenome)}</div>}</TableCell>
                  <TableCell>{profile.nome_completo}</TableCell>
                  <TableCell>{profile.cpfFormatado}</TableCell>
                  <TableCell>
                    <IconButton color="secondary" onClick={() => handleOpenDeleteDialog(profile)} title="Deixar de compartilhar seu perfil com este usuário">
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Button variant="outlined" fullWidth color="primary" onClick={handleOpenShareDialog}>
        Compartilhar Perfil
      </Button>

      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirmar Remoção</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deseja cancelar o acesso de {selectedProfileToDelete && `${selectedProfileToDelete.nome_completo}`} ao seu perfil?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleRemoveAccess} color="secondary">
            Remover
          </Button>
        </DialogActions>
      </Dialog>

      <ShareProfileModal open={openShareDialog} handleClose={handleCloseShareDialog} onShareSuccess={handleShareSuccess} />
    </div>
  );
};

export default ProfileAccessCard;
